import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export interface IProductFilterOption {
  id: number;
  name: string;
}

interface IProductFilterProps {
  filterName: string;
  options: IProductFilterOption[];
  selectedFilter: number | string | null;
  onChange: (option: IProductFilterOption) => void;
  valueKey?: string;
}

const ProductFilter: React.FC<IProductFilterProps> = ({
  filterName,
  options,
  selectedFilter,
  onChange,
  valueKey = 'id',
}) => (
  <div className="pr-3">
    <FormGroup tag="fieldset">
      {[{ id: -1, name: 'All' } as IProductFilterOption]
        .concat(options)
        .map((item, index) => (
          <FormGroup check key={`${filterName}${item.name}`}>
            <Label check>
              <Input
                type="radio"
                name={`${filterName}${index}`}
                checked={(item as any)[valueKey] === selectedFilter}
                onChange={() => onChange(item)}
              />
              {item.name}
            </Label>
          </FormGroup>
        ))}
    </FormGroup>
  </div>
);

export default ProductFilter;
