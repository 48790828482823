import React from 'react';

interface IconButtonProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps & { icon: string }> = ({
  icon,
  onClick,
  style = {},
  title = '',
  disabled = false,
}) => (
  <button className="btn-icon" disabled={disabled} style={style} title={title} onClick={onClick}>
    <i className={`fa ${icon}`} />
  </button>
);

export type { IconButtonProps };
export default IconButton;
