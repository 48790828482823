import React, { useState } from 'react';

import { Form, FormInput, FormDate, FormButton } from '@components/forms';

import { GenericFormValues } from '@models/forms';
import { IDropEvent } from '@models/dropEvent';

import yup from '@utils/yup';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
});

type EditDropEventFormProps = {
  onSubmit(values: GenericFormValues): void | Promise<any>;
  dropEvent: IDropEvent;
};

const EditDropEventForm: React.FC<EditDropEventFormProps> = ({
  onSubmit,
  dropEvent,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const initialValues = {
    name:  dropEvent.name,
    startDate: dropEvent.start_date,
    endDate: dropEvent.end_date,
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <FormInput name="name" label="Drop Event Name" type="text" placeholder="Enter drop event name" maxLength={128} />
      <FormDate name="startDate" label="Drop Start Date" placeholder="Enter drop start date" />
      <FormDate name="endDate" label="Drop End Date" placeholder="Enter drop end date" />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default EditDropEventForm;
