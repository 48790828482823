import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import DropEventForm from '@components/dropEvent/DropEventForm';
import ContentSection from '@components/common/ContentSection/ContentSection';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const CREATE_DROP_EVENT = `
  mutation CreateDropEvent($groupId: String!, $name: String!, $startDate: Date!, $endDate: Date!) {
    createDropEvent(data: { groupId: $groupId, name: $name, startDate: $startDate, endDate: $endDate }) {
      id
      name
    }
  }`;

const AddDropEvent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [createDropEvent] = useMutation(CREATE_DROP_EVENT)

  const handleSubmit = async (values: GenericFormValues) => {
    const { data, error } = await createDropEvent({ variables: values })

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Drop event "${data.createDropEvent.name}" has been added successfully`));
      history.push('/drop-events');
    }
  };

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Drop Event</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Basic Info</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <DropEventForm onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddDropEvent;
