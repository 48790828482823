import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import { createBrowserHistory } from 'history';

import App from 'App';
import store from './state/store';

import 'bootstrap/dist/css/bootstrap.css';
import '@assets/scss/zest-admin.css';
import '@assets/fonts/sf-ui-display.css';
import '@assets/fonts/simple-line-icons.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'font-awesome/css/font-awesome.min.css';

const history = createBrowserHistory();

const onRedirectCallback = (appState: any) => {
  // Use the router's history module to replace the url
  history.replace(appState ? appState.returnTo : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallback}
  >
    <ClientContext.Provider value={new GraphQLClient({ url: process.env.REACT_APP_API_MAIA_URL || '' })}>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </ClientContext.Provider>
  </Auth0Provider >,
  document.getElementById('root'),
);
