import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { remove } from 'lodash'
import { useQuery, useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { IconButton, EditButton, DeleteButton } from '@components/buttons';

import { selectIsAdminAccount } from '@selectors/account';
import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IVendor } from '@models/vendor';

const GET_VENDORS = `
  query GetVendors($vendor: Boolean!) {
    groups(vendor: $vendor) {
      id
      name
      region
    }
  }`;

const DELETE_VENDOR = `
mutation DeleteVendor($id: ID!) {
  deleteGroup(id: $id) {
    id
    name
  }
}`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Vendor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [vendorList, setVendorList] = useState<IVendor[]>([]);
  const [deleteGroup] = useMutation(DELETE_VENDOR)

  const { loading, error, data } = useQuery(GET_VENDORS, { variables: { vendor: true } })

  useEffect(() => {
    if (data) {
      setVendorList(data.groups)
    }
  }, [data])

  const handleManageProducts = (vendorId: IVendor['id']) => history.push(`/vendor-products/${vendorId}`);

  const handleEditClick = (vendor: IVendor, e: React.MouseEvent) => {
    history.push(`/edit-vendor/${vendor.id}`);
    e.stopPropagation();
  };

  const handleDeleteVendor = async (vendor: IVendor) => {
    const { data, error } = await deleteGroup({ variables: { id: vendor.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newVendorList = [...vendorList];
      remove(newVendorList, {
        id: vendor.id
      })
      setVendorList(newVendorList);
      dispatch(sendSuccessNotification(`Proxy vendor "${data.deleteGroup.name}" has been deleted successfully`));
    }
  };

  const getHeader = (isAdmin: boolean) => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Region', prop: 'region', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    ...(isAdmin
      ? [
        {
          title: '',
          prop: 'buttons',
          sortable: false,
          filterable: false,
          cellProps: {
            className: () => 'pull-right',
            style: { display: 'flex' },
          },
        },
      ]
      : []),
  ];

  const getBody = (vendorsList: IVendor[], isAdmin: boolean) =>
    vendorsList.map((vendor) => ({
      ...vendor,
      ...(isAdmin
        ? {
          buttons: (
            <React.Fragment>
              <IconButton icon="fa-cubes" onClick={() => handleManageProducts(vendor.id)} />
              <EditButton onClick={(e) => handleEditClick(vendor, e)} />{' '}
              <DeleteButton onClick={() => handleDeleteVendor(vendor)} />
            </React.Fragment>
          ),
        }
        : {}),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Vendors</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Vendors</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader(isAdminAccount)}
                      tableBody={getBody(vendorList, isAdminAccount)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Vendor;
