import Product from '@views/Product/Product';
import AddProduct from '@views/Product/AddProduct';
import EditProduct from '@views/Product/EditProduct';

import DropEvents from '@views/DropEvent/DropEvents';
import AddDropEvent from '@views/DropEvent/AddDropEvent';
import EditDropEvent from '@views/DropEvent/EditDropEvent';
import ManageDropEventProducts from '@views/DropEvent/ManageDropEventProducts';

import Users from '@views/User/Users';

import Vendors from '@views/Vendor/Vendors';
import AddVendor from '@views/Vendor/AddVendor';
import EditVendor from '@views/Vendor/EditVendor';
import ManageVendorProducts from '@views/Vendor/ManageVendorProducts';

import Subscription from '@views/Subscription';

import Groups from '@views/Group/Groups';
import AddGroup from '@views/Group/AddGroup';
import EditGroup from '@views/Group/EditGroup';
import ManageGroupProducts from '@views/Group/ManageGroupProducts';

import ProxyGroups from '@views/ProxyGroup/ProxyGroups';
import ProxyGroup from '@views/ProxyGroup/ProxyGroup';
import AddProxyGroup from '@views/ProxyGroup/AddProxyGroup';
import EditProxyGroup from '@views/ProxyGroup/EditProxyGroup';

import EcommerceSettings from '@views/Settings/Settings';

import { UserRole } from '@models/user';
import PrivateRoute from '@components/router/PrivateRoute';

import Channels from '@views/Channel/Channels';
import AddChannel from '@views/Channel/AddChannel';
import EditChannel from '@views/Channel/EditChannel';
import ManageChannelProducts from '@views/Channel/ManageChannelProducts';

import Releases from '@views/Release/Releases';
import ManageReleaseProducts from '@views/Release/ManageReleaseProducts';

import Automations from '@views/Automation/Automations';

import CloudProducts from '@views/CloudProduct/CloudProducts';
import ManageCloudProducts from '@views/CloudProduct/ManageCloudProducts';

const BASEDIR = process.env.REACT_APP_BASEDIR;

export interface IEcommerceRoute {
  path: string | string[];
  name: string;
  roles: UserRole[];
  component: React.ComponentType;
  route: typeof PrivateRoute;
  children?: IEcommerceRoute[];
  icon?: string;
  href?: string;
  type?: string;
  parentid?: string;
}

const ecommerceRoutes: IEcommerceRoute[] = [
  {
    path: BASEDIR + '/products',
    href: BASEDIR + '/products',
    name: 'Products',
    component: Product,
    route: PrivateRoute,
    icon: 'grid',
    type: 'dropdown',
    parentid: 'products',
    roles: [UserRole.Customer, UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/products', BASEDIR + '/edit-product/'],
        href: BASEDIR + '/products',
        name: 'Products',
        component: Product,
        route: PrivateRoute,
        roles: [UserRole.Customer, UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-product',
        href: BASEDIR + '/add-product',
        name: 'Add Product',
        component: AddProduct,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/edit-product/:productId',
        name: 'Edit Product',
        component: EditProduct,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    path: [ BASEDIR + '/cloud-products', BASEDIR + '/cloud-products/'],
    href: BASEDIR + '/cloud-products',
    name: 'Cloud Products',
    component: CloudProducts,
    route: PrivateRoute,
    icon: 'cloud-download',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/cloud-products', BASEDIR + '/cloud-products/'],
        href: BASEDIR + '/cloud-products',
        name: 'Cloud Products',
        component: CloudProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/cloud-products/:groupId',
        name: 'Manage Cloud Products',
        component: ManageCloudProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ]
  },
  {
    path: BASEDIR + '/drop-events',
    href: BASEDIR + '/drop-events',
    name: 'Drop Events',
    component: DropEvents,
    route: PrivateRoute,
    icon: 'event',
    type: 'dropdown',
    parentid: 'drop-events',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/drop-events', BASEDIR + '/edit-drop-event/'],
        href: BASEDIR + '/drop-events',
        name: 'Drop Events',
        component: DropEvents,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-drop-event',
        href: BASEDIR + '/add-drop-event',
        name: 'Add Drop Event',
        component: AddDropEvent,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/edit-drop-event/:dropEventId',
        name: 'Edit Drop Event',
        component: EditDropEvent,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/drop-event-products/:dropEventId',
        name: 'Manage Drop Event Products',
        component: ManageDropEventProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    path: [ BASEDIR + '/releases', BASEDIR + '/release-products/'],
    href: BASEDIR + '/releases',
    name: 'Releases',
    component: Releases,
    route: PrivateRoute,
    icon: 'energy',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/releases', BASEDIR + '/release-products/'],
        href: BASEDIR + '/releases',
        name: 'Releases',
        component: Releases,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/release-products/:releaseId',
        name: 'Manage Release Products',
        component: ManageReleaseProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ]
  },
  {
    path: BASEDIR + '/automations',
    href: BASEDIR + '/automations',
    name: 'Automations',
    component: Automations,
    route: PrivateRoute,
    icon: 'directions',
    parentid: 'automations',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/automations'],
        href: BASEDIR + '/automations',
        name: 'Automations',
        component: Automations,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ]
  },
  {
    path: BASEDIR + '/channels',
    href: BASEDIR + '/channels',
    name: 'Channels',
    component: Channels,
    route: PrivateRoute,
    icon: 'layers',
    type: 'dropdown',
    parentid: 'channels',
    roles: [UserRole.Customer, UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/channels', BASEDIR + '/channels/', BASEDIR + '/channel-products/'],
        href: BASEDIR + '/channels',
        name: 'Channels',
        component: Channels,
        route: PrivateRoute,
        roles: [UserRole.Customer, UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-channel',
        href: BASEDIR + '/add-channel',
        name: 'Add Channel',
        component: AddChannel,
        route: PrivateRoute,
        roles: [UserRole.Customer, UserRole.Administrator],
      },
      {
        path: BASEDIR + '/channels/:channelId',
        name: 'Edit Channel',
        component: EditChannel,
        route: PrivateRoute,
        roles: [UserRole.Customer, UserRole.Administrator],
      },
      {
        path: BASEDIR + '/channel-products/:channelId',
        name: 'Manage Channel Products',
        component: ManageChannelProducts,
        route: PrivateRoute,
        roles: [UserRole.Customer, UserRole.Administrator],
      },
    ],
  },
  {
    path: BASEDIR + '/vendors',
    href: BASEDIR + '/vendors',
    name: 'Vendors',
    component: Vendors,
    route: PrivateRoute,
    icon: 'briefcase',
    type: 'dropdown',
    parentid: 'vendors',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/vendors', BASEDIR + '/edit-vendor/', BASEDIR + '/vendor-products/'],
        href: BASEDIR + '/vendors',
        name: 'Vendors',
        component: Vendors,
        route: PrivateRoute,
        roles: [ UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-vendor',
        href: BASEDIR + '/add-vendor',
        name: 'Add Vendor',
        component: AddVendor,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/edit-vendor/:vendorId',
        name: 'Edit Vendor',
        component: EditVendor,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/vendor-products/:vendorId',
        name: 'Manage Vendor Products',
        component: ManageVendorProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    path: BASEDIR + '/groups',
    href: BASEDIR + '/groups',
    name: 'Groups',
    component: Groups,
    route: PrivateRoute,
    icon: 'tag',
    type: 'dropdown',
    parentid: 'groups',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/groups', BASEDIR + '/edit-group/', BASEDIR + '/group-products/'],
        href: BASEDIR + '/groups',
        name: 'Groups',
        component: Groups,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-group',
        href: BASEDIR + '/add-group',
        name: 'Add Group',
        component: AddGroup,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/edit-group/:groupId',
        name: 'Edit Group',
        component: EditGroup,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/group-products/:groupId',
        name: 'Manage Group Products',
        component: ManageGroupProducts,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    path: BASEDIR + '/proxy-groups',
    href: BASEDIR + '/proxy-groups',
    name: 'Proxies',
    component: Groups,
    route: PrivateRoute,
    icon: 'globe',
    type: 'dropdown',
    parentid: 'groups',
    roles: [UserRole.Administrator],
    children: [
      {
        path: [BASEDIR + '/proxy-groups', BASEDIR + '/proxy-group/', BASEDIR + '/edit-proxy-group/'],
        href: BASEDIR + '/proxy-groups',
        name: 'Proxy Groups',
        component: ProxyGroups,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/add-proxy-group',
        href: BASEDIR + '/add-proxy-group',
        name: 'Add Proxy Group',
        component: AddProxyGroup,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/proxy-group/:proxyGroupId',
        name: 'Proxy Group',
        component: ProxyGroup,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
      {
        path: BASEDIR + '/edit-proxy-group/:proxyGroupId',
        name: 'Edit Proxy Group',
        component: EditProxyGroup,
        route: PrivateRoute,
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    path: BASEDIR + '/users',
    href: BASEDIR + '/users',
    name: 'Users',
    icon: 'people',
    component: Users,
    route: PrivateRoute,
    roles: [UserRole.Administrator],
  },
    {
    path: BASEDIR + '/subscription/:sessionId?',
    href: BASEDIR + '/subscription',
    name: 'Subscription',
    icon: 'wallet',
    component: Subscription,
    route: PrivateRoute,
    roles: [UserRole.Customer],
  },
  {
    path: BASEDIR + '/settings',
    href: BASEDIR + '/settings',
    name: 'Settings',
    route: PrivateRoute,
    icon: 'settings',
    component: EcommerceSettings,
    roles: [UserRole.Customer, UserRole.Administrator],
  },
];
export default ecommerceRoutes;
