import { Route } from 'react-router-dom';

import EcommerceLayout from 'layouts/Ecommerce';
// import AuthLayout from '@layouts/Auth';
import BlankPage from '@layouts/BlankPage';

import Landing from '@views/Public/Landing';
import Terms from '@views/Public/Terms';
import Privacy from '@views/Public/Privacy';

import PrivateRoute from '@components/router/PrivateRoute';
import AnonymousRoute from '@components/router/AnonymousRoute';

var BASEDIR = process.env.REACT_APP_BASEDIR;

var indexRoutes = [
  // {
  //   path: BASEDIR + '/auth',
  //   name: 'Login',
  //   route: AnonymousRoute,
  //   component: AuthLayout,
  // },
  {
    path: BASEDIR + '/403',
    name: '403',
    route: Route,
    component: BlankPage,
  },
  { 
    path: BASEDIR + '/privacy',
    name: 'Privacy',
    route: AnonymousRoute, 
    component: Privacy, 
  },
  { 
    path: BASEDIR + '/terms',
    name: 'Terms',
    route: AnonymousRoute, 
    component: Terms, 
  },
  { 
    exact: true,
    path: BASEDIR + '/',
    name: 'Landing',
    route: AnonymousRoute, 
    component: Landing, 
  },
  /*
  { path: BASEDIR + '/register', name: 'Register', component: LoginPage },
  { path: BASEDIR + '/lockscreen', name: 'Lockscreen', component: BlankPage },
  { path: BASEDIR + '/403', name: '403', component: BlankPage },
  { path: BASEDIR + '/404', name: '404', component: BlankPage },
  { path: BASEDIR + '/405', name: '405', component: BlankPage },
  { path: BASEDIR + '/408', name: '408', component: BlankPage },
  { path: BASEDIR + '/500', name: '500', component: BlankPage },
  { path: BASEDIR + '/503', name: '503', component: BlankPage },
  { path: BASEDIR + '/offline', name: 'Offline', component: BlankPage },
  */
  {
    path: BASEDIR + '/',
    name: 'Dashboard',
    route: PrivateRoute,
    component: EcommerceLayout,
  },
  {
    path: '/',
    name: 'Dashboard',
    route: PrivateRoute,
    component: EcommerceLayout,
  },
];

export default indexRoutes;
