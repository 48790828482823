import { ActionTypes, Actions } from '@actions/types';
import { AccountState } from './interfaces';
import { Status } from '@reducers/enum';
import { responseErrorToUiFormError } from '@models/error';

export const initialState: AccountState = {
  status: Status.LOADING,
  data: null,
  error: null,
};

export default (state: AccountState = initialState, action: Actions): AccountState => {
  switch (action.type) {
    case ActionTypes.AUTHORIZE_ACCOUNT_REQUEST:
      return {
        ...state,
        status: Status.LOADING,
        data: null,
        error: null,
      };
    case ActionTypes.AUTHORIZE_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: Status.LOADED,
        data: action.payload,
        error: null,
      };
    case ActionTypes.AUTHORIZE_ACCOUNT_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        data: null,
        error: responseErrorToUiFormError(action.payload),
      };
    case ActionTypes.UNAUTHORIZE_ACCOUNT_REQUEST:
      return {
        ...state,
        status: Status.LOADING,
        error: null,
      };
    case ActionTypes.UNAUTHORIZE_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: Status.LOADED,
        data: null,
        error: null,
      };
    case ActionTypes.UNAUTHORIZE_ACCOUNT_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        error: responseErrorToUiFormError(action.payload),
      };
    default:
      return state;
  }
};

export * from './interfaces';
