//todo: cleanup 
export enum HttpErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export interface IErrorData {
  [key: string]: string | string[];
}
export interface IResponseError {
  errorCode: HttpErrorCode;
  errorMessage: string;
  errorData: IErrorData;
}

export interface IErrorFields {
  [key: string]: boolean;
}
export interface IUiError {
  errorCode: string;
  errorMessage: string;
}
export interface IUiFormError extends IUiError {
  errorFields: IErrorFields;
}

export const responseErrorToUiError = (error: IResponseError): IUiError => {
  const errorDataKeys = Object.keys(error.errorData || {});
  const errorMessage =
    errorDataKeys.length === 0
      ? error.errorMessage
      : errorDataKeys
          .map(
            (key: string) => `${key}: ` + ([] as string[]).concat(error.errorData[key]).join('\n'),
          )
          .join('\n');

  return {
    errorCode: String(error.errorCode),
    errorMessage: errorMessage,
  };
};

export const responseErrorToUiFormError = (error: IResponseError): IUiFormError => {
  const errorFields = Object.keys(error.errorData || {}).reduce(
    (fields: IErrorFields, key: string) => {
      fields[key] = !!error.errorData[key];
      return fields;
    },
    {},
  );

  return {
    ...responseErrorToUiError(error),
    errorFields: errorFields,
  };
};
