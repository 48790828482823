/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';

import Loader from '@components/common/Loader/Loader';

export interface BaseButtonProps {
    disabled?: boolean;
    focused?: boolean;
    onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    type?: 'button' | 'reset' | 'submit';
    color?: 'primary' | 'secondary';
}

export interface FormButtonProps extends BaseButtonProps {
    ignoreDirty?: boolean;
    submitting?: boolean;
}

const FormButton: FC<FormButtonProps> = ({ children, ignoreDirty = false, submitting = false, ...baseButtonProps }) => {
    const { disabled = false, onClick, type = 'button', color = 'primary' } = baseButtonProps;
    const { dirty, handleReset, handleSubmit } = useFormikContext();

    const buttonIsDisabled = useMemo(() => {
        switch (type) {
            case 'submit':
                return disabled || (!ignoreDirty && !dirty) || submitting;
            case 'reset':
                return disabled || (!ignoreDirty && !dirty) || submitting;
            default:
                return disabled || submitting;
        }
    }, [disabled, dirty, ignoreDirty, submitting, type]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (e) e.preventDefault();
        if (buttonIsDisabled) return;

        if (type === 'submit') handleSubmit();

        if (type === 'reset') handleReset();

        if (type === 'button' && onClick) onClick(e);
    };

    return (
        <div className="form-row">
            <div className="form-group col-md-12">
                <button
                    {...baseButtonProps}
                    disabled={buttonIsDisabled}
                    onClick={handleClick}
                    type={type}
                    className={`btn btn-${color}`}>
                    {type === 'submit' && submitting ? <Loader size={1} color="white" /> : children}
                </button>
            </div>
        </div>
    );
};

export default FormButton;
