import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'graphql-hooks';
import { Row, Col } from 'reactstrap';

import EditChannelForm from '@components/channel/EditChannelForm';
import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const GET_CHANNEL = `
query GetChannel($id: ID!) {
  channel(id: $id) {
    id
    name
    webhook
    region
    alias
    group_id
  }
}`;

const MODIFY_CHANNEL = `
mutation ModifyChannel(
    $id: ID!,
    $name: String!,
    $webhook: String!,
    $alias: Boolean!,
    $groupId: ID,
  ) {
    modifyChannel(
        id: $id
        data: {
          name: $name, 
          webhook: $webhook, 
          alias: $alias,
          groupId: $groupId,
        }
    ) {
      channel {
        id
        name
      }
      error {
        message
      }
    }
  }`;

type ParamTypes = {
  channelId: string;
};

const EditChannel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_CHANNEL, { variables: { id: params.channelId } })
  const [modifyChannel] = useMutation(MODIFY_CHANNEL)

  const handleSubmit = async (values: GenericFormValues) => {
    const { data, error } = await modifyChannel(
      {
        variables: {
          id: params.channelId,
          name: values.name,
          webhook: values.webhook,
          alias: !!values.groupId,
          groupId: !!values.groupId ? values.groupId : undefined,
        }
      }
    )

    if (error) dispatch(sendErrorNotification(error));
    else if (data.modifyChannel.error) dispatch(sendErrorNotification(data.modifyChannel.error));
    else {
      dispatch(sendSuccessNotification(`Channel "${data.modifyChannel.channel.name}" has been updated successfully`));
      history.push('/channels');
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Edit Channel</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Channel Information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <EditChannelForm channel={data.channel} onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditChannel;
