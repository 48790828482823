import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { isEmpty, remove } from 'lodash'
import { useQuery, useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { IconButton, EditButton, DeleteButton } from '@components/buttons';

import { selectIsAdminAccount } from '@selectors/account';
import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IDropEvent } from '@models/dropEvent';

const GET_DROP_EVENTS = `
  query GetDropEvents {
    dropEvents {
      id
      name
      group {
        name
      }
      products {
        sku
      }
      start_date
      end_date
    }
  }`;

const DELETE_DROP_EVENT = `
  mutation DeleteDropEvent($id: ID!) {
    deleteDropEvent(id: $id) {
      id
      name
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const DropEvents: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [dropEventList, setDropEventList] = useState<IDropEvent[]>([]);

  const { loading, error, data } = useQuery(GET_DROP_EVENTS);
  const [deleteDropEvent] = useMutation(DELETE_DROP_EVENT)

  useEffect(() => {
    if (data) {
      setDropEventList(data.dropEvents)
    }
  }, [data])

  const handleManageProducts = (dropEventId: IDropEvent['id']) => history.push(`/drop-event-products/${dropEventId}`);

  const handleEditClick = (dropEvent: IDropEvent, e: React.MouseEvent) => {
    history.push(`/edit-drop-event/${dropEvent.id}`);
    e.stopPropagation();
  };

  const handleDeleteDropEvent = async (dropEvent: IDropEvent) => {
    const { data, error } = await deleteDropEvent({ variables: { id: dropEvent.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newDropEventList = [...dropEventList];
      remove(newDropEventList, {
        id: dropEvent.id
      })
      setDropEventList(newDropEventList);
      dispatch(sendSuccessNotification(`Drop event "${data.deleteDropEvent.name}" has been deleted successfully`));
    }
  };

  const getHeader = (isAdmin: boolean) => [
    { title: 'Site', prop: 'site', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    { title: 'SKUs', prop: 'sku', sortable: true, filterable: true },
    { title: 'Start Date', prop: 'start_date', sortable: true, filterable: true },
    { title: 'End Date', prop: 'end_date', sortable: true, filterable: true },
    ...(isAdmin
      ? [
        {
          title: '',
          prop: 'buttons',
          sortable: false,
          filterable: false,
          cellProps: {
            className: () => 'pull-right',
          },
        },
      ]
      : []),
  ];

  const getBody = (dropEventList: any[], isAdmin: boolean): IDropEvent[] =>
    dropEventList.map((dropEvent) => ({
      ...dropEvent,
      site: dropEvent.group.name,
      sku: !isEmpty(dropEvent.products) ? dropEvent.products.map(({sku}: {sku: string}) => sku).join(", ") : "[Add Products]",
      start_date: moment(dropEvent.start_date).format('lll'),
      end_date: moment(dropEvent.end_date).format('lll'),
      ...(isAdmin
        ? {
          buttons: (
            <React.Fragment>
              <IconButton icon="fa-cubes" onClick={() => handleManageProducts(dropEvent.id)} />
              <EditButton onClick={(e) => handleEditClick(dropEvent, e)} />{' '}
              <DeleteButton onClick={() => handleDeleteDropEvent(dropEvent)} />
            </React.Fragment>
          ),
        }
        : {}),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Drop Events</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Drop Events</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader(isAdminAccount)}
                      tableBody={getBody(dropEventList, isAdminAccount)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DropEvents;
