import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from "graphql-hooks";
import { Row, Col } from 'reactstrap';
import { filter, remove } from 'lodash';

import ProductFilter, { IProductFilterOption } from '@components/product/ProductFilter';
import ProductList from '@components/product/ProductList';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendErrorNotification, sendSuccessNotification } from '@actions/notificationActions';

import { selectIsAdminAccount } from '@selectors/account';
import { IGroup } from '@models/group';
import { IProduct } from '@models/product';

const GET_PRODUCTS_WITH_GROUPS = `
  query GetProductsWithGroups {
    groups {
      id
      name
      vendor
    }
    products {
      id
      thumbnail
      title
      url
      groups {
        id
      }
    }
  }`;

const DELETE_PRODUCT = `
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
      title
    }
  }`;

const Product: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [vendorId, setVendorId] = useState<number>(-1);
  const [groupId, setGroupId] = useState<number>(-1);
  const [search, setSearch] = useState<string>('');
  const [productList, setProductList] = useState<IProduct[]>([]);

  const { loading, error, data } = useQuery(GET_PRODUCTS_WITH_GROUPS)
  const [deleteProduct] = useMutation(DELETE_PRODUCT)

  useEffect(() => {
    if (data) {
      setProductList(data.products)
    }
  }, [data])

  const handleChangeVendorFilter = (vendor: IGroup) => {
    setVendorId(vendor.id);
  };

  const handleChangeGroupFilter = (group: IGroup) => {
    setGroupId(group.id);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleEditProduct = (product: IProduct) => history.push(`/edit-product/${product.id}`);

  const handleDeleteProduct = async (product: IProduct) => {
    const { data, error } = await deleteProduct({ variables: { id: product.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newProductList = [...productList];
      remove(newProductList, {
        id: product.id
      })
      setProductList(newProductList);
      dispatch(sendSuccessNotification(`Product "${data.deleteProduct.title}" has been deleted successfully`));
    }
  };

  const filteredList = useMemo(() => {
    if (loading || !productList) return [];

    let filtered: any[] = productList;

    if (search) {
      filtered = filtered.filter((item: IProduct) => item.title && item.title.toUpperCase().indexOf(search.toUpperCase()) > -1);
    }

    if (vendorId > 0) {
      filtered = filter(filtered, { groups: [{ id: vendorId }] })
    }

    if (groupId > 0) {
      filtered = filter(filtered, { groups: [{ id: groupId }] })
    }

    return filtered;
  }, [productList, search, vendorId, groupId]);

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Products</h1>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="content-body">
                <div className="row">
                  <div className="col-md-6 col-sm-7 col-8">
                    <div className="input-group primary">
                      <input
                        type="text"
                        className="form-control search-page-input"
                        placeholder="Enter your search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-5 col-4">
                    <button type="button" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                  <div className="clearfix"></div>
                  <br />
                  <div className="col-lg-12 search_data d-flex">
                    <div className="mw-20">
                      <div>
                        <h4>Vendor:</h4>
                        <ProductFilter
                          filterName="vendors"
                          options={data.groups.filter((g: IGroup) => g.vendor).map((g: IGroup) => ({
                            id: g.id,
                            name: g.name
                          }))}
                          selectedFilter={vendorId}
                          onChange={(option: IProductFilterOption) =>
                            handleChangeVendorFilter(option as IGroup)
                          }
                        />
                      </div>
                      <div>
                        <h4>Group:</h4>
                        <ProductFilter
                          filterName="groups"
                          options={data.groups.filter((g: IGroup) => !g.vendor).map((g: IGroup) => ({
                            id: g.id,
                            name: g.name
                          }))}
                          selectedFilter={groupId}
                          onChange={(option: IProductFilterOption) =>
                            handleChangeGroupFilter(option as IGroup)
                          }
                        />
                      </div>
                    </div>
                    <div className="media-body ml-5">
                      <ProductList
                        search={filteredList}
                        onEditClick={isAdminAccount ? handleEditProduct : null}
                        onDeleteClick={isAdminAccount ? handleDeleteProduct : null}
                        onSelectChannelClick={null}
                      // channelsList={channelsList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Product;
