import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'graphql-hooks';
import { Row, Col } from 'reactstrap';

import EditGroupForm from '@components/group/EditGroupForm';
import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

const GET_GROUP = `
query GetGroup($id: ID!) {
  group(id: $id) {
    id
    name
    region
    instances
    delay
    monitor_proxy_group_id
    checkout_proxy_group_id
  }
}`;

const MODIFY_GROUP = `
  mutation ModifyGroup(
    $id: ID!,
    $name: String!,
    $vendor: Boolean!,
    $instances: Int,
    $delay: Int,
    $monitorProxyGroupId: Int,
    $checkoutProxyGroupId: Int,
  ) {
    modifyGroup(
        id: $id
        data: {
          name: $name, 
          vendor: $vendor,
          instances: $instances,
          delay: $delay,
          monitor_proxy_group_id: $monitorProxyGroupId,
          checkout_proxy_group_id: $checkoutProxyGroupId,
        }
    ) {
      id
      name
    }
  }`;

type ParamTypes = {
  groupId: string;
};

const EditGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_GROUP, { variables: { id: params.groupId } })
  const [modifyGroup] = useMutation(MODIFY_GROUP)

  const handleSubmit = async (values: any) => {
    const { data, error } = await modifyGroup(
      {
        variables: {
          id: params.groupId,
          vendor: false,
          ...values,
          instances: parseInt(values.instances),
          delay: parseInt(values.delay),
          monitorProxyGroupId: parseInt(values.monitorProxyGroupId),
          checkoutProxyGroupId: parseInt(values.checkoutProxyGroupId),
        }
      }
    )

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Group "${data.modifyGroup.name}" has been updated successfully`));
      history.push('/groups');
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Edit Group</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Group Information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <EditGroupForm group={data.group} onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditGroup;
