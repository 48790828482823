import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'graphql-hooks';

import { Form, FormInput, FormSelect, FormButton } from '@components/forms';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { InputOption, GenericFormValues } from '@models/forms';
import { IGroup } from '@models/group';
import { IProxyGroup } from '@models/proxyGroup';

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_GROUPS = `
query GetGroups($region: String!, $vendor: Boolean!) {
  proxyGroups {
    id
    name
  }
  groups(region: $region, vendor: $vendor) {
    id
    name
  }
}`;

type EditGroupFormProps = {
  group: IGroup;
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const EditGroupForm: React.FC<EditGroupFormProps> = ({
  group,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_GROUPS, { variables: { region: group.region, vendor: true } })

  const proxyGroupOptions: InputOption[] = useMemo(() => data ? data.proxyGroups.map((proxyGroup: IProxyGroup) => ({ value: proxyGroup.id, label: proxyGroup.name })) : [], [data]);

  const validationSchema = useMemo(() => yup.object().shape({
    name: yup.string().required().test('group-already-exists', 'This name already exists', (name) => {
      return data && data.groups ? !data.groups.map((group: IGroup) => group.name).filter((name: string) => name !== group.name).includes(name) : false;
    }),
    instances: yup.number().nullable(),
    delay: yup.number().nullable(),
    monitorProxyGroupId: yup.number(),
    checkoutProxyGroupId: yup.number(),
  }), [data]);

  const initialValues = {
    name: group.name,
    instances: group.instances,
    delay: group.delay,
    monitorProxyGroupId: group.monitor_proxy_group_id ? group.monitor_proxy_group_id : '',
    checkoutProxyGroupId: group.checkout_proxy_group_id ? group.checkout_proxy_group_id : '',
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      <FormInput name="name" label="Name" type="text" placeholder="Enter name" maxLength={128} />
      <FormInput name="instances" label="Instances" type="number" placeholder="Enter instances" maxLength={9} />
      <FormInput name="delay" label="Delay" type="number" placeholder="Enter delay" maxLength={9} />
      <FormSelect name="monitorProxyGroupId" label="Monitor Proxy Group" options={proxyGroupOptions} placeholder="Select monitor proxy group" />
      <FormSelect name="checkoutProxyGroupId" label="Checkout Proxy Group" options={proxyGroupOptions} placeholder="Select checkout proxy group" />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
}

export default EditGroupForm;
