import React, { ReactNode } from 'react';
import { isArray } from 'lodash';

const parseGraphQLErrors = (error: any): ReactNode => {
    const getErrorMessages = (gqe: any): string[] => {
        if (gqe) {
            if (gqe.body) {
                const body = JSON.parse(gqe.body)
                if (body.errors) {
                    return body.errors.map((e: any) => (e.message))
                }
                return [body['error-message']]
            } else if (isArray(gqe)) {
                return gqe.map((e: any) => (e.message))
            }
        }
        return [];
    }

    let errorList: string[] = [];

    errorList.push(...getErrorMessages(error.httpError))
    errorList.push(...getErrorMessages(error.graphQLErrors))

    return (<ul>{errorList.map((msg: any, index: number) => (<li key={index}>{msg}</li>))}</ul>);
}

export const formatError = (error: any) => {
    if (typeof error === 'string') {
        return error;
    } else {
        if (error) {
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.message) {
                        return error.response.data.message;
                    }
                    return error.response.data;
                } else if (error.response.errors) {
                    return (<ul>{error.response.errors.map((err: any, index: number) => (<li key={index}>{err.message}</li>))}</ul>);
                }
            } else if (error.message) {
                return error.message;
            } else if (error.fetchError) {
                return error.fetchError.toString();
            } else if (error.httpError || error.graphQLErrors) {
                return parseGraphQLErrors(error);
            }
            return JSON.stringify(error);
        }
    }
};
