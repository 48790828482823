import React from 'react';

import maiaLogo from '@assets/img/maia-logo.png';

const Logo: React.FC = () => (
  <div className="logo">
    <a href="/" className="logo-mini">
      <div className="logo-img">
        <img className="maiaLogo" src={maiaLogo} alt="Maia Monitors 2021" width="50" height="50" />
      </div>
    </a>
    <div className="logo-full profile-details col-12">
      <h3>
        <a
          href="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          <img className="maiaLogo" src={maiaLogo} alt="Maia Monitors 2021" width="50" height="50" />
          Maia Monitors
        </a>
      </h3>
    </div>
  </div>
);

export default Logo;
