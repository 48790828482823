import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isNumber } from 'lodash';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { UserRole, IAccount } from '@models/user';

type PrivateRouteProps = {
  account: IAccount | null;
  component: React.ComponentType<any>
  path: string | string[];
  roles?: UserRole[];
  exact?: boolean;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ account, component, roles, ...args }) => {
  // check if user has permissions to access provided path
  if (account && isNumber(account.role) && roles && !roles.includes(account.role)) {
    return (<Redirect to="/products" />)
  }

  return (<Route component={withAuthenticationRequired(component)} {...args} />);
};

export default PrivateRoute;
