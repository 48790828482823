import React, { ReactNode } from 'react';
import { Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

export const renderFormError = (name: string, hideErrorText = false): ReactNode => (
    <span className="icon-red">
        {hideErrorText ? null : <ErrorMessage name={name} />}
    </span>
);

export const renderFormLabel = (
    name: string,
    label?: string,
): ReactNode =>
    label ? (<Label for={name}>{label}</Label>) : null;
