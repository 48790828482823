import React, { useState } from 'react';

import { Form, FormInput, FormButton } from '@components/forms';

import { GenericFormValues } from '@models/forms';
import { IProxyGroup } from '@models/proxyGroup';

import yup from '@utils/yup';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  proxies: yup.string().required(),
});

type EditProxyGroupFormProps = {
  onSubmit(values: GenericFormValues): void | Promise<any>;
  proxyGroup: IProxyGroup;
};

const EditProxyGroupForm: React.FC<EditProxyGroupFormProps> = ({
  onSubmit,
  proxyGroup,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const initialValues = {
    name: proxyGroup ? proxyGroup.name : '',
    proxies: proxyGroup ? proxyGroup.proxies : ''
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <FormInput name="name" label="Proxy Group Name" type="text" placeholder="Enter proxy group name" maxLength={128} />
      <FormInput name="proxies" label="Proxies" type="textarea" rows={10} placeholder="Enter proxies" />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default EditProxyGroupForm;
