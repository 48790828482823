import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'graphql-hooks';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import CustomConfirmationModal from '@components/common/ConfirmationModal/CustomConfirmationModal';
import { Form, FormInput, FormButton, FormSelect } from '@components/forms';

import { InputOption, GenericFormValues } from '@models/forms';
import { IChannel } from '@models/channel';
import { IGroup } from '@models/group';
import { DISCORD_URL_REGEX } from '@variables/validations'

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_GROUPS = `
query GetGroups($region: String!) {
    groups(region: $region) {
      id
      name
      vendor
    }
}`;

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  webhook: yup.string().required().matches(DISCORD_URL_REGEX, { message: 'Discord webhook is not valid' }),
});

type EditChannelFormProps = {
  channel: IChannel;
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const EditChannelForm: React.FC<EditChannelFormProps> = ({
  channel,
  onSubmit
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [confirmationFormValues, setConfirmationFormValues] = useState<any>();

  const { loading, error, data } = useQuery(GET_GROUPS, { variables: { region: channel.region } });

  const groupOptions: InputOption[] = useMemo(() => data ? data.groups.map((group: IGroup) => ({ value: group.id, label: `${group.vendor ? 'Vendor' : 'Group'}: ${group.name}` })) : [], [data]);

  const initialValues = {
    name: channel.name,
    webhook: channel.webhook,
    groupId: channel.group_id,
  };

  const handleSubmit = async (values: GenericFormValues) => {
    if (channel && !channel.alias && values.groupId) {
      setConfirmationFormValues(values);
      setShowConfirmDialog(true);
    } else {
      setSubmitting(true);

      await onSubmit(values);

      setSubmitting(false);
    }
  }

  const handleCancel = () => setShowConfirmDialog(false);

  const handleConfirm = async () => {
    setShowConfirmDialog(false);
    setSubmitting(true);

    await onSubmit(confirmationFormValues);

    setSubmitting(false);
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        <FormInput name="name" label="Name" type="text" placeholder="Enter name" maxLength={128} />
        <FormInput name="webhook" label="Webhook" type="text" placeholder="Enter webhook" maxLength={256} />
        <FormSelect name="groupId" label="Product Alias Group" options={groupOptions} placeholder="Select alias group" />
        <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
      </Form>
      <CustomConfirmationModal
        confirmationMessage="By changing channel to aliased, you will loose all previously saved channel products"
        isOpen={showConfirmDialog}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
}

export default EditChannelForm;
