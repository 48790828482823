/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, ChangeEvent, FocusEvent } from 'react';
import { Field } from 'formik';
import { FormGroup, Label, Input } from 'reactstrap';
import get from 'lodash/get';

import useFormContext from '@hooks/useFormContext';

import { InputOption, BaseFormComponentProps } from '@models/forms';

import { renderFormError, renderFormLabel } from '@utils/forms';

export interface BaseMultiProps {
    disabled?: boolean;
    error?: boolean;
    invalid?: boolean;
    focused?: boolean;
    name?: string;
    onBlur?(e: FocusEvent<HTMLTextAreaElement>): void;
    onChange?(e: ChangeEvent<HTMLTextAreaElement>): void;
    options: InputOption[];
    value: string;
}

type ComponentProps = BaseFormComponentProps<BaseMultiProps>;

const FromMulti: FC<ComponentProps> = ({
    hideErrorBlock = false,
    hideErrorText = false,
    label,
    ...baseCheckProps
}) => {
    const { name, options } = baseCheckProps;
    const { errors, touched } = useFormContext();

    const error = !!get(errors, name) && !!get(touched, name);

    return (
        <div className="form-row">
            <div className="form-group col-md-12">
                {renderFormLabel(name, label)}
                <div>
                    {options.map((option) => (
                        <FormGroup check key={`${name}_${option.value}`}>
                            <Label check>
                                <Field name={name} value={option.value} as={Input} type="checkbox" className="" invalid={error} />
                                {option.label}
                            </Label>
                        </FormGroup>
                    ))}
                </div>
                {hideErrorBlock ? null : renderFormError(name, hideErrorText)}
            </div>
        </div>
    );
};

export default FromMulti;
