import React, { useState } from 'react';

import { Form, FormInput, FormButton } from '@components/forms';

import { GenericFormValues } from '@models/forms';
import { IProxyGroup } from '@models/proxyGroup';

import yup from '@utils/yup';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

type ProxyGroupFormProps = {
  proxyGroup?: IProxyGroup;
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const ProxyGroupForm: React.FC<ProxyGroupFormProps> = ({
  onSubmit,
  proxyGroup,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const initialValues = {
    name: '',
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <FormInput name="name" label="Proxy Group Name" type="text" placeholder="Enter proxy group name" maxLength={128} />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default ProxyGroupForm;
