import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { remove } from 'lodash'
import { useQuery, useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { DeleteButton, EditButton } from '@components/buttons';

import MonitorModal from '@components/automation/MonitorModal';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IAutomationGroup } from '@models/automation';
import { GenericFormValues } from '@models/forms';

const GET_AUTOMATION_GROUPS = `
  query GetAutomationGroups {
    automationGroups {
      id
      name
      is_default_run
      resources
    }
  }`;

const DELETE_AUTOMATION_GROUP = `
mutation DeleteAutomationGroup($id: ID!) {
  deleteAutomationGroup(id: $id) {
    id
    name
  }
}`;

const CREATE_AUTOMATION_GROUP = `
  mutation CreateAutomationGroup(
    $name: String!,
    $isDefaultRun: Boolean!,
    $resources: String!
  ) {
    createAutomationGroup(
      data: {
        name: $name,
        isDefaultRun: $isDefaultRun,
        resources: $resources
      }
    ) {
      id
    }
  }`;

const UPDATE_AUTOMATION_GROUP = `
  mutation UpdateAutomationGroup(
    $id: ID!,
    $name: String!,
    $isDefaultRun: Boolean!,
    $resources: String!
  ) {
    updateAutomationGroup(
      id: $id,
      data: {
        name: $name,
        isDefaultRun: $isDefaultRun,
        resources: $resources
      }
    )
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Automations: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showMonitorModal, setShowMonitorModal] = useState<boolean>(false);
  const [monitorModal, setMonitorModal] = useState<IAutomationGroup>();
  const [groupList, setGroupList] = useState<IAutomationGroup[]>([]);

  const { loading, error, data } = useQuery(GET_AUTOMATION_GROUPS);
  const [deleteAutomationGroup] = useMutation(DELETE_AUTOMATION_GROUP);
  const [createAutomationGroup] = useMutation(CREATE_AUTOMATION_GROUP);
  const [updateAutomationGroup] = useMutation(UPDATE_AUTOMATION_GROUP);

  useEffect(() => {
    if (data) {
      setGroupList(data.automationGroups)
    }
  }, [data])

  const handleAddMonitor = async () => {
    setMonitorModal(undefined);
    setShowMonitorModal(true);
  }

  const handleEditMonitor = async (group: IAutomationGroup) => {
    setMonitorModal(group);
    setShowMonitorModal(true);
  }

  const handleCancelMonitorModal = () => setShowMonitorModal(false);

  const handleSaveMonitorModal = async (values: GenericFormValues) => {
    const { data, error } = monitorModal ? await updateAutomationGroup(
      {
        variables: {
          id: monitorModal.id,
          name: values.name,
          isDefaultRun: values.isDefaultRun === 'true',
          resources: values.resources,
        }
      }
    ) : await createAutomationGroup(
      {
        variables: {
          name: values.name,
          isDefaultRun: values.isDefaultRun === 'true',
          resources: values.resources,
        }
      }
    );

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Automation group has been ${monitorModal ? 'modified' : 'created'} successfully`));
      setShowMonitorModal(false);
      history.push('/automations');
    }
  };

  const handleDeleteAutomationGroup = async (group: IAutomationGroup) => {
    const { data, error } = await deleteAutomationGroup({ variables: { id: group.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newGroupList = [...groupList];
      remove(newGroupList, {
        id: group.id
      })
      setGroupList(newGroupList);
      dispatch(sendSuccessNotification(`Automation group "${data.deleteAutomationGroup.name}" has been deleted successfully`));
    }
  };

  const getHeader = () => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    { title: 'Is Default Run', prop: 'isDefaultRun', sortable: true },
    { title: 'Resources Count', prop: 'resourcesCount', sortable: false, filterable: false },
    {
      title: '',
      prop: 'buttons',
      sortable: false,
      filterable: false,
      cellProps: {
        className: () => 'pull-right',
        style: { display: 'flex' },
      },
    },
  ];

  const getBody = (groupList: IAutomationGroup[]) =>
    groupList.map((group) => ({
      ...group,
      isDefaultRun: group.is_default_run ? "Yes" : "No",
      resourcesCount: group.resources ? group.resources.length : 0,
      buttons: (
        <React.Fragment>
          <EditButton onClick={() => handleEditMonitor(group)} />
          <DeleteButton onClick={() => handleDeleteAutomationGroup(group)} />
        </React.Fragment>
      ),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Automations</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Automations</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader()}
                      tableBody={getBody(groupList)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                    <Row>
                      <Col xs={12} md={12}>
                        <button
                          onClick={handleAddMonitor}
                          type="button"
                          className="btn btn-primary">
                          Add Monitor
                        </button>
                      </Col>
                    </Row>
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
      <MonitorModal
        isOpen={showMonitorModal}
        onSave={handleSaveMonitorModal}
        onCancel={handleCancelMonitorModal}
        monitor={monitorModal}
      />
    </div>
  );
};

export default Automations;
