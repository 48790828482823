import React, { useState, useMemo } from 'react';
import { useQuery } from "graphql-hooks";
import { useDispatch } from 'react-redux';

import { Form, FormInput, FormButton, FormSelect, FormDate } from '@components/forms';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { GenericFormValues, InputOption } from '@models/forms';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_GROUPS = `
  query GetGroups($vendor: Boolean!) {
    groups(vendor: $vendor) {
      id
      name
    }
  }`;

const validationSchema = yup.object().shape({
  groupId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
});

type DropEventFormProps = {
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const DropEventForm: React.FC<DropEventFormProps> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_GROUPS, { variables: { vendor: true } })

  const siteOptions: InputOption[] = useMemo(() => {
    if (!data) return [];

    return data.groups.map((g: any) => ({ value: g.id, label: g.name }));
  }, [data]);

  const initialValues = {
    groupId: '',
    name: '',
    startDate: '',
    endDate: '',
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <FormSelect name="groupId" label="Site" options={siteOptions} placeholder="Select site" />
      <FormInput name="name" label="Drop Event Name" type="text" placeholder="Enter drop event name" maxLength={128} />
      <FormDate name="startDate" label="Drop Start Date" placeholder="Enter drop start date" />
      <FormDate name="endDate" label="Drop End Date" placeholder="Enter drop end date" />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default DropEventForm;
