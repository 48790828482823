import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { Row, Col } from 'reactstrap';
import { useQuery } from "graphql-hooks";

import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendErrorNotification } from '@actions/notificationActions';

const GET_PROXY_GROUP = `
  query GetProxyGroup($id: ID!) {
    proxyGroup(id: $id) {
      id
      name
      proxies {
        id
        host
        port
        username
        password
      }
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

type ParamTypes = {
  proxyGroupId: string;
};

const ProxyGroup: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<ParamTypes>();

  const [proxyList, setProxyList] = useState([]);

  const { loading, error, data } = useQuery(GET_PROXY_GROUP, { variables: { id: params.proxyGroupId } })

  useEffect(() => {
    if (data) {
      setProxyList(data.proxyGroup.proxies)
    }
  }, [data])

  const getHeader = () => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Host', prop: 'host', sortable: true, filterable: true },
    { title: 'Port', prop: 'port', sortable: true, filterable: true },
    { title: 'Username', prop: 'username', sortable: true, filterable: true },
    { title: 'Password', prop: 'password', sortable: true, filterable: true },
  ];

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Proxy Group</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>ID: {params.proxyGroupId}</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader()}
                      tableBody={proxyList}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProxyGroup;
