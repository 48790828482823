import React from 'react';
import { Alert } from 'reactstrap';


const Unavailable: React.FC = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Alert color="secondary">
      Content Unavailable
    </Alert>
  </div>
);

export default Unavailable;
