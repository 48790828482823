import React from 'react';

import HeaderPublic from '@components/common/Header/HeaderPublic';
import Footer from '@components/common/Footer/Footer';

import amazonLogo from '@assets/img/amazon-logo.svg';
import walmartLogo from '@assets/img/walmart-logo.svg';
import targetLogo from '@assets/img/target-logo.png';
import bestbuyLogo from '@assets/img/bestbuy-logo.svg';
import finishlineLogo from '@assets/img/finishline-logo.svg';
import shopifyLogo from '@assets/img/shopify-logo.svg';
import footlockerLogo from '@assets/img/footlocker-logo.svg';

import lightningFeature from '@assets/img/feature-lightning.svg';
import clockFeature from '@assets/img/feature-clock.svg';
import fireFeature from '@assets/img/feature-fire.svg';
import dollarFeature from '@assets/img/feature-dollar.svg';
import tickFeature from '@assets/img/feature-tick.svg';

import stellaraioLogo from '@assets/img/logo-stellaraio.png';
import stellaraioScreen from '@assets/img/screen-stellaraio.png';
import lumenLogo from '@assets/img/logo-lumen.png';
import lumenScreen from '@assets/img/screen-lumen.png';

const Landing: React.FC = () => {
    return (
        <div className="landingpage">
            <HeaderPublic />
            <div id="" className="home">
                <h1>
                    Monitors to help your members<br />
                    <span className="primaryTextColor">feast</span> on every release
                </h1>
                <a className="primary applyNow" href="/products">Apply now</a>
            </div>
            <div id="features" className="features">
                <h2>POWERFUL TOOLS</h2>
                <h3>Make your productivity lightning fast</h3>
                <div className="pentagon">
                    <div>
                        <div className="marketing">
                            <span className="num">1</span>
                            <span className="txt">Lightning Fast Speed</span>
                            <img src={lightningFeature} width="20" height="16" />
                        </div>
                        <div className="marketing">
                            <span className="num">2</span>
                            <span className="txt">Set up in minutes</span>
                            <img src={clockFeature} width="20" height="20" />
                        </div>
                        <div className="marketing">
                            <span className="num">3</span>
                            <span className="txt">Monitor the hottest products with our research</span>
                            <img src={fireFeature} width="20" height="14" />
                        </div>
                    </div>
                    <div>
                        <div className="marketing">
                            <span className="num">4</span>
                            <span className="txt">The best price</span>
                            <img src={dollarFeature} width="16" height="20" />
                        </div>
                        <div className="marketing">
                            <span className="num">5</span>
                            <span className="txt">99% uptime guarantee</span>
                            <img src={tickFeature} width="20" height="20" />
                        </div>
                    </div>
                </div>
                <div className="supportingPlatforms">
                    <h2>SUPPORTING MAJOR PLATFORMS</h2>
                    <h3>An unmatched sitelist</h3>
                    <div className="totalSites">
                        <img src={amazonLogo} height="64" />
                        <img src={walmartLogo} height="41" />
                        <img src={targetLogo} height="90" />
                        <img src={bestbuyLogo} height="58" />
                        <img src={finishlineLogo} height="28" />
                        <img src={shopifyLogo} height="40" />
                        <img src={footlockerLogo} height="76" />
                        &amp; MORE
                    </div>
                </div>
                <div className="stats">
                    <div className="info">
                        <div className="value">1000+</div>
                        <div className="title">PROFITABLE PRODUCTS</div>
                    </div>
                    <div className="info">
                        <div className="value">10ms</div>
                        <div className="title">AVERAGE SPEED</div>
                    </div>
                    <div className="info">
                        <div className="value">100+</div>
                        <div className="title">SITELIST</div>
                    </div>
                    <div className="info">
                        <div className="value">99.9%</div>
                        <div className="title">UPTIME</div>
                    </div>
                </div>
            </div>
            <div id="pricing" className="pricing">
                <h3>Pricing</h3>
                <div className="article">
                    Receive full access to all Maia features all at one unbeatable price. For questions and inquiries regarding our pricing please contact us at <a href="mailto:support@maiamonitors.com">support@maiamonitors.com</a>
                </div>
                <div className="premium">
                    <div className="title">Premium</div>
                    <div className="price">$200/month</div>
                    <a className="primary applyNow" href="/products">Apply now</a>
                </div>
                <h2>PARTNERS</h2>
                <h3>Powering the biggest bots in the world</h3>
                <div className="partners">
                    <div className="site">
                        <div className="brand">
                            <img src={stellaraioLogo} width="30" height="33" />
                            <img src={stellaraioScreen} width="271" height="71" />
                        </div>
                        <div className="actions">
                            <span className="partnerName">STELLAR AIO</span>
                            <a href="https://stellara.io/" target="_blank">Visit Site</a>
                        </div>
                    </div>
                    <div className="site">
                        <div className="brand">
                            <img src={lumenLogo} width="31" height="29" />
                            <img src={lumenScreen} width="274" height="73" />
                        </div>
                        <div className="actions">
                            <span className="partnerName">LUMEN</span>
                            <span>coming soon</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard">
                <div className="overlay">
                    <h1>
                        Equip your server with the highest<br />
                        quality monitors <span className="primaryTextColor">in minutes.</span>
                    </h1>
                    <a className="primary applyNow" href="/products">Apply now &#10142;</a>
                </div>
            </div>
            <Footer isPublic />
        </div>
    );
};

export default Landing;
