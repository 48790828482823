import React from 'react';
import { IProduct } from '@models/product';
import ProductListItem from './ProductListItem';
import { IChannel } from '@models/channel';

interface Props {
  search: IProduct[];
  onEditClick: ((product: IProduct) => void) | null;
  onDeleteClick: ((product: IProduct) => void) | null;
  onSelectChannelClick: ((product: IProduct, channelId: number) => void) | null;
  // channelsList: IChannel[];
}

const ProductList: React.FC<Props> = ({
  search,
  onEditClick,
  onDeleteClick,
  onSelectChannelClick,
  // channelsList,
}) => (
  <section className="box">
    <div className="content-body">
      <div className="row">
        {search.length > 0 ? (
          search.map((item: IProduct) => (
            <ProductListItem
              key={item.id}
              product={item}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onSelectChannelClick={onSelectChannelClick}
              // channelsList={channelsList}
            />
          ))
        ) : (
          <div className="no-data">There is no data to be displayed</div>
        )}
      </div>
    </div>
  </section>
);

export default ProductList;
