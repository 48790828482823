import React, { useState, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useQuery } from 'graphql-hooks';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import { Form, FormInput, FormCheck, FormMulti, FormButton } from '@components/forms';

import { InputOption, GenericFormValues } from '@models/forms';
import { IProduct } from '@models/product';
import { IGroup } from '@models/group';

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_PRODUCT_OPTIONS = `
query GetProductOptions($region: String!) {
    vendors: groups(vendor: true, region: $region) {
      id
      name 
    }
    groups(vendor: false, region: $region) {
      id
      name 
    }
}`;

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  sku: yup.string().required(),
  url: yup.string().required(),
  thumbnail: yup.string().required(),
  offerId: yup.string(),
  active: yup.boolean().required(),
  private: yup.boolean().required(),
  test: yup.boolean().required(),
  vendors: yup.array().test('select-vendor', 'Select at least one vendor', (vendors) =>
    (vendors ? vendors.length > 0 : false)),
  groups: yup.array(),
});

interface Props {
  product: IProduct;
  onSubmit(values: GenericFormValues): void | Promise<any>;
}

const ProductForm: React.FC<Props> = ({ product, onSubmit }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_PRODUCT_OPTIONS, { variables: { region: product.region } })

  const vendorOptions: InputOption[] = useMemo(() => data ? data.vendors.map((vendor: IGroup) => ({ value: vendor.id, label: vendor.name })) : [], [data]);

  const groupOptions: InputOption[] = useMemo(() => data ? data.groups.map((group: IGroup) => ({ value: group.id, label: group.name })) : [], [data]);

  const initialValues = {
    title: product.title,
    sku: product.sku,
    url: product.url,
    thumbnail: product.thumbnail,
    offerId: product.offer_id ? product.offer_id : '',
    active: product.active,
    private: product.private,
    test: product.test,
    vendors: product.groups ? product.groups.filter((group: IGroup) => group.vendor).map((group: IGroup) => (group.id.toString())) : [],
    groups: product.groups ? product.groups.filter((group: IGroup) => !group.vendor).map((group: IGroup) => (group.id.toString())) : [],
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      <Row>
        <Col xs={6} md={6}>
          <FormInput name="title" label="Title" type="text" placeholder="Enter title" maxLength={256} />
          <FormInput name="sku" label="SKU" type="text" placeholder="Enter SKU" maxLength={128} />
          <FormInput name="url" label="URL" type="text" placeholder="Enter URL" maxLength={256} />
          <FormInput name="thumbnail" label="Thumbnail" type="text" placeholder="Enter thumbnail" maxLength={256} />
          <FormInput name="offerId" label="Offer Id" type="text" placeholder="Enter offer id" maxLength={256} />
          <FormCheck name="active" label="Is active" />
          <FormCheck name="private" label="Is private" />
          <FormCheck name="test" label="Is test product" />
        </Col>
        <Col xs={6} md={6}>
          <h4>Region: {product.region}</h4>
          <FormMulti name="vendors" label="Vendors" options={vendorOptions} />
          <FormMulti name="groups" label="Groups" options={groupOptions} />
        </Col>
      </Row>
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default ProductForm;
