import { Dispatch } from 'redux';
import { gql } from 'graphql-request';
import auth0 from '@auth0/auth0-react';
import { AppThunk } from '@reducers/index';
import { getGraphqlApiInstance } from '@services/graphqlApi';

import { sendErrorNotification } from '@actions/notificationActions';

import {
  AuthorizeAccountRequestAction,
  AuthorizeAccountSuccessAction,
  AuthorizeAccountErrorAction,
  UnauthorizeAccountRequestAction,
  UnauthorizeAccountSuccessAction,
  UnauthorizeAccountErrorAction,
} from './interfaces';
import { ActionTypes } from '@actions/types';
import { IAccount, IUser } from '@models/user';
import { IResponseError, HttpErrorCode } from '@models/error';

const GET_USER_QUERY = gql`
  query GetUser {
    user {
      id
      email
      auth0_id
      role
      active
    }
  }
`;

const CREATE_SELF_USER = gql`
  mutation createSelfUser($email: String!) {
    createSelfUser(data: { email: $email }) {
      id
      email
      auth0_id
      role
      active
    }
  }
`;

/* Authorize Actions */
export const authorizeAccountRequest = (): AuthorizeAccountRequestAction => ({
  type: ActionTypes.AUTHORIZE_ACCOUNT_REQUEST,
});

export const authorizeAccountSuccess = (payload: IAccount): AuthorizeAccountSuccessAction => ({
  type: ActionTypes.AUTHORIZE_ACCOUNT_SUCCESS,
  payload,
});

export const authorizeAccountError = (payload: IResponseError): AuthorizeAccountErrorAction => ({
  type: ActionTypes.AUTHORIZE_ACCOUNT_ERROR,
  payload,
});

const getAccountPayload = (customer: IUser, auth0user: auth0.User): IAccount => ({
  ...customer,
  display_name: auth0user.nickname || '',
  email_verified: auth0user.email_verified || false,
  photo_url: auth0user.picture || '',
});

export const authorize =
  (user: auth0.User, accessToken: string): AppThunk =>
  async (dispatch: Dispatch) => {
    dispatch<AuthorizeAccountRequestAction>(authorizeAccountRequest());

    var account = undefined;

    try {
      let data = await getGraphqlApiInstance(accessToken).request(GET_USER_QUERY);
      account = data.user;
    } catch {}

    if (!account) {
      // new user does not exists, create self user
      try {
        let data = await getGraphqlApiInstance(accessToken).request(CREATE_SELF_USER, {
          email: user.email,
        });
        account = data.createSelfUser;
      } catch (e) {
        dispatch<AuthorizeAccountErrorAction>(authorizeAccountError(e));
        dispatch<any>(sendErrorNotification(e));
        return;
      }
    } else if (!account.active) {
      const message = "User account is disabled!"
      dispatch<any>(sendErrorNotification(message));

      dispatch<AuthorizeAccountErrorAction>(authorizeAccountError({
        errorCode: HttpErrorCode.FORBIDDEN,
        errorMessage: message,
        errorData: { "error": message}
      }));
      return;
    }

    dispatch<AuthorizeAccountSuccessAction>(
      authorizeAccountSuccess(getAccountPayload(account, user)),
    );
  };

/* Unauthorize Actions */
export const unauthorizeAccountRequest = (): UnauthorizeAccountRequestAction => ({
  type: ActionTypes.UNAUTHORIZE_ACCOUNT_REQUEST,
});

export const unauthorizeAccountSuccess = (): UnauthorizeAccountSuccessAction => ({
  type: ActionTypes.UNAUTHORIZE_ACCOUNT_SUCCESS,
});

export const unauthorizeAccountError = (
  payload: IResponseError,
): UnauthorizeAccountErrorAction => ({
  type: ActionTypes.UNAUTHORIZE_ACCOUNT_ERROR,
  payload,
});

export const unauthorize = (): AppThunk => async (dispatch: Dispatch) => {
  dispatch<UnauthorizeAccountSuccessAction>(unauthorizeAccountSuccess());
};

export * from './interfaces';
