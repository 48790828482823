import React from 'react';
import { Route } from 'react-router-dom';

type AnonymousRouteProps = {
  component: React.ElementType;
  exact?: boolean;
};

const AnonymousRoute: React.FC<AnonymousRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default AnonymousRoute;
