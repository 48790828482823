import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import AddVendorForm from '@components/vendor/AddVendorForm';
import ContentSection from '@components/common/ContentSection/ContentSection';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

const CREATE_VENDOR = `
  mutation CreateVendor(
    $name: String!, 
    $vendor: Boolean!, 
    $region: String!,
    $instances: Int,
    $delay: Int,
    $monitorProxyGroupId: Int,
    $checkoutProxyGroupId: Int,
  ) {
      createGroup(
        data: {
          name: $name, 
          vendor: $vendor, 
          region: $region,
          instances: $instances,
          delay: $delay,
          monitor_proxy_group_id: $monitorProxyGroupId,
          checkout_proxy_group_id: $checkoutProxyGroupId,
        }
      ) {
        id
        name
      }
  }`;

const AddVendor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [createGroup] = useMutation(CREATE_VENDOR)

  const handleSubmit = async (values: any) => {
    const { data, error } = await createGroup(
      { 
        variables: { 
          name: values.name, 
          vendor: true,
          region: values.region,
          instances: parseInt(values.instances),
          delay: parseInt(values.delay),
          monitorProxyGroupId: parseInt(values.monitorProxyGroupId),
          checkoutProxyGroupId: parseInt(values.checkoutProxyGroupId),
        } 
      }
    );
    
    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Vendor "${data.createGroup.name}" has been added successfully`));
      history.push('/vendors');
    }
  };

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Vendor</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Vendor Information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <AddVendorForm onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddVendor;
