import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { remove } from 'lodash'
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from "graphql-hooks";

import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import { IconButton, EditButton, DeleteButton } from '@components/buttons';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';
import { isActiveMembership } from '@utils/membership';

import { IChannel } from '@models/channel';

const GET_USER_CHANNELS = `
  query GetCustomerChannels {
    user {
      role
      subscription_active
      subscription_expiry
      channels {
        id
        name
        webhook
        alias
        group {
          name
          region
        }
      }
    }
  }`;

const DELETE_CHANNEL = `
  mutation DeleteChannel($id: ID!) {
    deleteChannel(id: $id) {
      id
      name
    }
  }`;


const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Channels: React.FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [channelList, setChannelList] = useState<IChannel[]>([]);

  const { loading, error, data } = useQuery(GET_USER_CHANNELS)
  const [deleteChannel] = useMutation(DELETE_CHANNEL)

  useEffect(() => {
    if (data) {
      setChannelList(data.user.channels)
    }
  }, [data])

  const handleManageProducts = (channelId: IChannel['id']) => history.push(`/channel-products/${channelId}`);

  const handleEdit = (channelId: IChannel['id']) => history.push(`/channels/${channelId}`);

  const handleDelete = async (channel: IChannel) => {
    const { data, error } = await deleteChannel({ variables: { id: channel.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newChannelList = [...channelList];
      remove(newChannelList, {
        id: channel.id
      })
      setChannelList(newChannelList);
      dispatch(sendSuccessNotification(`Channel "${data.deleteChannel.name}" has been added successfully`));
    }
  }

  const getHeader = (isActiveMembership: boolean) => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    { title: 'Webhook', prop: 'webhook', sortable: true, filterable: true },
    { title: 'Alias', prop: 'alias', sortable: true, filterable: true },
    ...(isActiveMembership ? [
      {
        title: '',
        prop: 'buttons',
        sortable: false,
        filterable: false,
        cellProps: {
          className: () => 'pull-right',
        },
      },
    ] : [])
  ];

  const getBody = (channelsList: IChannel[], isActiveMembership: boolean) =>
    channelsList.map((channel) => ({
      ...channel,
      alias: (channel.group ? `${channel.group.region}: ${channel.group.name}` : undefined),
      ...(isActiveMembership ? {
        buttons: (
          <React.Fragment>
            {!channel.alias && (<IconButton icon="fa-cubes" onClick={() => handleManageProducts(channel.id)} />)}
            <EditButton onClick={() => handleEdit(channel.id)} />
            <DeleteButton onClick={() => handleDelete(channel)} />
          </React.Fragment>
        ),
      } : {}),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Channels</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Channels</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader(isActiveMembership(data.user))}
                      tableBody={getBody(channelList, isActiveMembership(data.user))}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Channels;
