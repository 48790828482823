import React, { useState } from 'react';
import IconButton, { IconButtonProps } from '@components/buttons/IconButton';
import DeleteConfirmationModal from '@components/common/ConfirmationModal/DeleteConfirmationModal';

interface DeleteButtonProps extends IconButtonProps {
  onClick: () => void;
  shouldConfirm?: boolean;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick: doDelete,
  shouldConfirm = true,
  ...props
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleDelete = (e: React.MouseEvent) => {
    shouldConfirm ? setShowConfirmDialog(true) : doDelete();
    e.stopPropagation();
  };

  const handleCancelDelete = () => setShowConfirmDialog(false);

  const handleConfirmDelete = () => {
    doDelete();
    setShowConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <IconButton {...props} icon="fa-trash-o" onClick={(e) => handleDelete(e)} />
      <DeleteConfirmationModal
        isOpen={showConfirmDialog}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </React.Fragment>
  );
};

export default DeleteButton;
