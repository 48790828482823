import React, { useState } from 'react';

import { Form, FormInput, FormButton } from '@components/forms';

import { GenericFormValues } from '@models/forms';
import { IUser } from '@models/user';
import { HEX_COLOR_REGEX, FIELD_REQUIRED_ERROR } from '@variables/validations'

import yup from '@utils/yup';

const validationSchema = yup.object().shape({
    discordUserId: yup.string().required(FIELD_REQUIRED_ERROR),
    discordServerId: yup.string().required(FIELD_REQUIRED_ERROR),
    discordUsername: yup.string().required(FIELD_REQUIRED_ERROR),
    discordServerName: yup.string().required(FIELD_REQUIRED_ERROR),
    discordServerLogo: yup.string().required(FIELD_REQUIRED_ERROR),
    discordServerColor: yup.string().required(FIELD_REQUIRED_ERROR).matches(HEX_COLOR_REGEX, { excludeEmptyString: true, message: 'Discord server color should match HEX color: #000000 or #000' }),
    discordServerStatement: yup.string().required(FIELD_REQUIRED_ERROR),
});

type DiscordFormProps = {
    onSubmit(values: GenericFormValues): void | Promise<any>;
    user: IUser;
};

const DiscordForm: React.FC<DiscordFormProps> = ({ onSubmit, user }) => {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const initialValues = {
        discordUserId: user && user.discord_user_id ? user.discord_user_id : '',
        discordServerId: user && user.discord_server_id ? user.discord_server_id : '',
        discordUsername: user && user.discord_username ? user.discord_username : '',
        discordServerName: user && user.discord_server_name ? user.discord_server_name : '',
        discordServerLogo: user && user.discord_server_logo ? user.discord_server_logo : '',
        discordServerColor: user && user.discord_server_color ? user.discord_server_color : '',
        discordServerStatement: user && user.discord_server_statement ? user.discord_server_statement : '',
    };

    const handleSubmit = async (values: GenericFormValues) => {
        setSubmitting(true);

        await onSubmit(values);

        setSubmitting(false);
    };

    return (
        <div className="col-12">
            <section className="box">
                <header className="panel_header">
                    <h2 className="title float-left">Discord Information</h2>
                </header>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8"></div>
                        <Form initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                            <FormInput name="discordUserId" label="User ID" type="text" placeholder="Enter discord user id" maxLength={20} />
                            <FormInput name="discordUsername" label="Username" type="text" placeholder="Enter discord username" maxLength={128} />
                            <FormInput name="discordServerId" label="Server ID" type="text" placeholder="Enter discord server id" maxLength={20} />
                            <FormInput name="discordServerName" label="Server name" type="text" placeholder="Enter discord server name" maxLength={128} />
                            <FormInput name="discordServerLogo" label="Server logo" type="text" placeholder="Enter discord server logo" maxLength={256} />
                            <FormInput name="discordServerColor" label="Server color" type="text" placeholder="Enter discord HEX color" maxLength={7} />
                            <FormInput name="discordServerStatement" label="Server Statement" type="text" placeholder="Enter discord statement" maxLength={128} />
                            <FormButton type="submit" submitting={submitting} ignoreDirty>
                                Save
                            </FormButton>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default DiscordForm;
