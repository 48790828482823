import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';
import { concat } from 'lodash';

import AddProductForm from '@components/product/AddProductForm';
import ContentSection from '@components/common/ContentSection/ContentSection';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

const CREATE_PRODUCT = `
  mutation CreateProduct(
    $title: String!,
    $sku: String!,
    $url: String!,
    $thumbnail: String!,
    $offerId: String,
    $region: String!,
    $active: Boolean!,
    $private: Boolean!,
    $test: Boolean!,
    ) {
        createProduct(
          data: {
            title: $title, 
            sku: $sku, 
            url: $url, 
            thumbnail: $thumbnail, 
            offer_id: $offerId,
            region: $region,
            active: $active, 
            private: $private,
            test: $test,
          }
        ) {
          id
          title
        }
    }`;

const ADD_PRODUCT_TO_GROUPS = `
  mutation AddProductToGroups(
    $productId: ID!,
    $groupIds: [ID]!
    ) {
      addProductToGroups(productId: $productId, groupIds: $groupIds)
    }
`;

const AddProduct: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [createProduct] = useMutation(CREATE_PRODUCT)
  const [addProductToGroups] = useMutation(ADD_PRODUCT_TO_GROUPS)

  const handleSubmit = async (values: any) => {
    const { data: createProductData, error: createProductError } = await createProduct(
      {
        variables: {
          ...values,
        }
      }
    )

    if (createProductError) {
      dispatch(sendErrorNotification(createProductError));
      return
    }

    const { data: addProductToGroupsData, error: addProductToGroupsError } = await addProductToGroups(
      {
        variables: {
          productId: createProductData.createProduct.id,
          groupIds: concat(values.vendors, values.groups)
        }
      }
    )

    if (addProductToGroupsError) {
      dispatch(sendErrorNotification(addProductToGroupsError));
      return
    }

    dispatch(sendSuccessNotification(`Product "${createProductData.createProduct.title}" has been added successfully`));
    history.push('/products');
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Product</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Product information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12">
                      <AddProductForm onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div >
  );
};

export default AddProduct;
