import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { Row, Col } from 'reactstrap';

import ProductModal from './ProductModal';

import { EditButton, DeleteButton } from '@components/buttons';

import { GenericFormValues } from '@models/forms';
import { IReleaseProduct } from '@models/product';

const classes = {
  table: 'table-striped table-hover',
};

type ManageProductsProps = {
  vendorId: string;
  products: IReleaseProduct[];
  onSubmit(values: GenericFormValues, id?: number): void;
  onDelete(id: number): void;
};

const ManageProducts: React.FC<ManageProductsProps> = ({ vendorId, products, onSubmit, onDelete }) => {
  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [productModal, setProductModal] = useState<IReleaseProduct>();

  const handleCancelProductModal = () => setShowProductModal(false);

  const handleSaveProductModal = (values: GenericFormValues) => {
    onSubmit(
      values,
      productModal ? productModal.releaseProductId : undefined
    );
    setShowProductModal(false);
  };

  const handleAddProduct = async () => {
    setProductModal(undefined);
    setShowProductModal(true);
  }

  const handleEditPreset = async (product: IReleaseProduct) => {
    setProductModal(product);
    setShowProductModal(true)
  }

  const handleDeleteProduct = async (product: IReleaseProduct) => {
    onDelete(product.releaseProductId)
  }

  const getHeader = () => [
    { title: 'ID', prop: 'id', sortable: true, filterable: false },
    { title: 'SKU', prop: 'sku', sortable: true, filterable: false },
    { title: 'Title', prop: 'title', sortable: true, filterable: false },
    { title: 'Preset', prop: 'preset', sortable: false, filterable: false },
    { title: '', prop: 'buttons', sortable: false, filterable: false, cellProps: { className: () => 'pull-right', style: { display: 'flex' } } }
  ];

  const getBody = (products: IReleaseProduct[]) =>
    products.map((product) => ({
      ...product,
      buttons: (
        <React.Fragment>
          <EditButton onClick={() => handleEditPreset(product)} />
          <DeleteButton onClick={() => handleDeleteProduct(product)} />
        </React.Fragment>
      ),
    }));

  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          <Datatable
            tableHeaders={getHeader()}
            tableBody={getBody(products)}
            classes={classes}
            initialSort={{ prop: 'id', isAscending: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <button
            onClick={handleAddProduct}
            type="button"
            className="btn btn-primary">
            Add Product
          </button>
        </Col>
      </Row>
      <ProductModal
        isOpen={showProductModal}
        onSave={handleSaveProductModal}
        onCancel={handleCancelProductModal}
        vendorId={vendorId}
        product={productModal}
      />
    </div>
  );
};

export default ManageProducts;
