import React, { FC } from 'react';
import { Form as FormikForm, Formik } from 'formik';

import { GenericFormValues } from '@models/forms';

interface ComponentProps {
  className?: string;
  initialValues?: GenericFormValues;
  onSubmit(values: GenericFormValues): void | Promise<any>;
  validationSchema?: any;
  enableReinitialize?: boolean;
}

const Form: FC<ComponentProps> = ({
  children,
  className,
  onSubmit,
  initialValues = {},
  validationSchema,
  enableReinitialize,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
    >
      {() => (
        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
          <FormikForm className={className}>{children}</FormikForm>
        </div>
      )}
    </Formik>
  );
};

export default Form;
