/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, ChangeEvent, FocusEvent } from 'react';
import { Field } from 'formik';
import { Input } from 'reactstrap';
import get from 'lodash/get';

import useFormContext from '@hooks/useFormContext';

import { BaseFormComponentProps } from '@models/forms';

import { renderFormError, renderFormLabel } from '@utils/forms';

export interface BaseInputProps {
    disabled?: boolean;
    error?: boolean;
    invalid?: boolean;
    focused?: boolean;
    name?: string;
    onBlur?(e: FocusEvent<HTMLTextAreaElement>): void;
    onChange?(e: ChangeEvent<HTMLTextAreaElement>): void;
    placeholder?: string;
    type?: 'text' | 'textarea' | 'number' | 'password';
    rows?: number;
    value: string;
    maxLength?: number;
}

type ComponentProps = BaseFormComponentProps<BaseInputProps>;

const FormInput: FC<ComponentProps> = ({
    hideErrorBlock = false,
    hideErrorText = false,
    label,
    ...baseInputProps
}) => {
    const { name } = baseInputProps;
    const { errors, touched } = useFormContext();

    const error = !!get(errors, name) && !!get(touched, name);

    return (
        <div className="form-row">
            <div className="form-group col-md-12">
                {renderFormLabel(name, label)}
                <Field {...baseInputProps} as={Input} invalid={error} />
                {hideErrorBlock ? null : renderFormError(name, hideErrorText)}
            </div>
        </div>
    );
};

export default FormInput;
