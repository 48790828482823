import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from "graphql-hooks";

import ContentSection from '@components/common/ContentSection/ContentSection';
import ManageProducts from '@components/product/ManageProducts';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IProduct } from '@models/product';

const GET_PRODUCTS = `
  query GetProducts($id: ID!) {
    products {
      id
      sku
      title
    }
    channel(id: $id) {
      alias
      region
      products {
        id
      }
    }
  }`;

const MODIFY_CHANNEL_PRODUCTS = `
  mutation ModifyChannelProducts(
    $channelId: ID!,
    $productIds: [ID]!
  ) {
    modifyChannelProducts(
      channelId: $channelId,
      productIds: $productIds
    )
  }
`;

type ParamTypes = {
  channelId: string;
};

const ManageChannelProducts: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_PRODUCTS, { variables: { id: params.channelId } })
  const [modifyChannelProducts] = useMutation(MODIFY_CHANNEL_PRODUCTS)

  const handleSubmit = async (productIds: number[]) => {
    const { data, error } = await modifyChannelProducts(
      {
        variables: {
          channelId: params.channelId,
          productIds: productIds,
        }
      }
    )

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Channel products has been modified successfully`));
      history.push('/channels');
    }
  };

  if (loading) {
    return <Loader />;
  } else if (!!data.channel.alias) {
    dispatch(sendErrorNotification('Cannot modify products on aliased channel'));
    history.push('/channels');
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Channel Products</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>Product Management</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <ManageProducts
                      region={data.channel.region}
                      productIds={data.channel.products.map((product: IProduct) => product.id)}
                      onSubmit={handleSubmit} />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ManageChannelProducts;
