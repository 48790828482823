import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import ProxyGroupForm from '@components/proxy/ProxyGroupForm';
import ContentSection from '@components/common/ContentSection/ContentSection';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const CREATE_PROXY_GROUP = `
  mutation CreateProxyGroup($name: String!) {
    createProxyGroup(name: $name) {
      id
      name
    }
  }`;

const AddProxyGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [createProxyGroup] = useMutation(CREATE_PROXY_GROUP)

  const handleSubmit = async (values: GenericFormValues) => {
    const { data, error } = await createProxyGroup({ variables: { name: values.name } })

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Proxy group "${data.createProxyGroup.name}" has been added successfully`));
      history.push('/proxy-groups');
    }
  };

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Proxy Group</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Basic Info</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <ProxyGroupForm onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddProxyGroup;
