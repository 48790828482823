
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { findIndex, toSafeInteger } from 'lodash'
import { Row, Col, CustomInput, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from "graphql-hooks";

import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import { EditButton, ConfirmButton, CancelButton } from '@components/buttons';

import { sendErrorNotification } from '@actions/notificationActions';

import { IUser, UserRole } from '@models/user';

const GET_USERS = `
  query GetUsers {
    users {
      id
      auth0_id
      email
      role
      active
    }
  }`;

const MODIFY_USER = `
  mutation ModifyUser(
    $id: ID!,
    $role: Int,
    $active: Boolean,
  ) {
    modifyUser(
        id: $id
        data: {
          role: $role, 
          active: $active, 
        }
    ) {
      id
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Users: React.FC = () => {
  const [updates, setUpdates] = useState<{ [key: string]: IUser }>({});
  const [userList, setUserList] = useState<IUser[]>([]);

  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_USERS)
  const [modifyUser] = useMutation(MODIFY_USER)

  useEffect(() => {
    if (data) {
      setUserList(data.users)
    }
  }, [data])

  const handleActiveToggle = (user: IUser, e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdates({
      ...updates,
      [user.id]: {
        ...(updates[String(user.id)] || {}),
        active: e.target.checked,
      },
    });
  };

  const handleRoleSelect = (user: IUser, e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdates({
      ...updates,
      [user.id]: {
        ...(updates[String(user.id)] || {}),
        role: e.target.value,
      },
    });
  };

  const handleEditClick = (user: IUser) => {
    setUpdates({
      ...updates,
      [user.id]: {},
    });
  };

  const handleCancelClick = (user: IUser) => {
    setUpdates({
      ...updates,
      [user.id]: null,
    });
  };

  const handleConfirmClick = async (user: IUser) => {
    const { data, error } = await modifyUser(
      {
        variables: {
          ...updates[user.id],
          id: user.id,
          role: updates[user.id].role ? toSafeInteger(updates[user.id].role) : user.role,
          active: updates[user.id].active !== undefined ? updates[user.id].active : user.active
        }
      }
    )

    if (error) dispatch(sendErrorNotification(error));
    else {
      let users = [...userList];
      var index = findIndex(users, { id: user.id });
      users.splice(index, 1, { ...user, ...updates[user.id] });

      setUserList(users);
      setUpdates({
        ...updates,
        [user.id]: null,
      });
    }
  };

  const getHeader = () => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Auth0 ID', prop: 'auth0_id', sortable: true, filterable: true },
    { title: 'Email', prop: 'email', sortable: true, filterable: true },
    { title: 'Role', prop: 'role', sortable: true, filterable: true },
    { title: 'Active', prop: 'toggle_button', sortable: false, filterable: false },
    {
      title: '',
      prop: 'buttons',
      sortable: false,
      filterable: false,
      cellProps: {
        className: () => 'pull-right',
      },
    },
  ];

  const getRoleCell = (user: IUser, customerUpdates: IUser) => (
    <Input
      type="select"
      id={`user_role_${user.id}`}
      name={`role_${user.id}`}
      disabled={!customerUpdates}
      value={customerUpdates ? customerUpdates.role : user.role}
      onChange={(e) => handleRoleSelect(user, e)}
      style={{ minHeight: '40px', padding: '10px 14px' }}
    >
      {Object.keys(UserRole).filter((key: string) => !isNaN(Number(key))).map((role: string) => (
        <option key={role} value={role}>
          {UserRole[parseInt(role)]}
        </option>
      ))}
    </Input>
  );

  const getActiveCell = (user: IUser, customerUpdates: IUser) => (
    <CustomInput
      type="switch"
      id={`user_active_${user.id}`}
      name={`active_${user.id}`}
      checked={customerUpdates && customerUpdates.active !== undefined ? customerUpdates.active : user.active}
      disabled={!customerUpdates}
      onChange={(e) => handleActiveToggle(user, e)}
    />
  );

  const getButtonsCell = (user: IUser, customerUpdates: IUser) => (
    <div style={{ width: '78px', textAlign: 'right' }}>
      {!!customerUpdates ? (
        <React.Fragment>
          <CancelButton title="Cancel" onClick={() => handleCancelClick(user)} />
          <ConfirmButton title="Confirm" onClick={() => handleConfirmClick(user)} />
        </React.Fragment>
      ) : (
        <EditButton title="Edit User" onClick={() => handleEditClick(user)} />
      )}
    </div>
  );

  const getBody = (userList: IUser[]) =>
    userList.map((user) => ({
      ...user,
      role: getRoleCell(user, updates[user.id]),
      toggle_button: getActiveCell(user, updates[user.id]),
      buttons: getButtonsCell(user, updates[user.id]),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Users</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Users</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader()}
                      tableBody={getBody(userList)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Users;
