import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useManualQuery } from 'graphql-hooks';
import { some } from 'lodash';

import { Form, FormInput, FormButton, FormSelect } from '@components/forms';

import { InputOption, GenericFormValues } from '@models/forms';
import { IGroup } from '@models/group';
import { DISCORD_URL_REGEX } from '@variables/validations'
import { regions } from '@variables/region'

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_REGION_GROUPS = `
  query GetGroups($region: String!) {
      groups(region: $region) {
        id
        name
        vendor
      }
  }`;

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  region: yup.string().required(),
  webhook: yup.string().required().matches(DISCORD_URL_REGEX, { message: 'Discord webhook is not valid' }),
});

const initialValues = {
  name: '',
  webhook: '',
  region: '',
  groupId: '',
};

type AddChannelFormProps = {
  disabledSubmit: boolean;
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const AddChannelForm: React.FC<AddChannelFormProps> = ({
  disabledSubmit,
  onSubmit
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [regionGroups, setRegionGroups] = useState<any>();

  const [getRegionGroups] = useManualQuery(GET_REGION_GROUPS)

  const regionOptions: InputOption[] = regions.map((r: any) => ({ value: r, label: r }));

  const groupOptions: InputOption[] = useMemo(() => regionGroups ? regionGroups.groups.map((group: IGroup) => ({ value: group.id, label: `${group.vendor ? 'Vendor' : 'Group'}: ${group.name}` })) : [], [regionGroups]);

  const fetchRegionGroups = async (region: string) => {
    const { data: regionGroupsData, error: regionGroupsError } = await getRegionGroups({
      variables: { region }
    });

    if (regionGroupsError) {
      dispatch(sendErrorNotification('Couldn\'t load region vendors and groups'));
      return
    }

    setRegionGroups(regionGroupsData);
  }


  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit({
      ...values,
      groupId: some(groupOptions, { value: values.groupId }) ? values.groupId : '',
    });

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      <FormInput name="name" label="Name" type="text" placeholder="Enter name" maxLength={128} />
      <FormInput name="webhook" label="Webhook" type="text" placeholder="Enter webhook" maxLength={256} />
      <FormSelect name="region" label="Region" options={regionOptions} placeholder="Select region" onChange={(e) => fetchRegionGroups(e.target.value)} />
      <FormSelect name="groupId" label="Product Alias Group" options={groupOptions} placeholder="Select alias group" />
      <FormButton type="submit" submitting={submitting} disabled={disabledSubmit} ignoreDirty>Save</FormButton>
    </Form>
  );
}

export default AddChannelForm;
