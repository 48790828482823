import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { remove } from 'lodash'
import { useQuery, useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { EditButton, DeleteButton } from '@components/buttons';

import { selectIsAdminAccount } from '@selectors/account';
import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IProxyGroup } from '@models/proxyGroup';

const GET_PROXY_GROUPS = `
  query GetProxyGroups {
    proxyGroups {
      id
      name
    }
  }`;

const DELETE_PROXY_GROUP = `
  mutation DeleteProxyGroup($id: ID!) {
    deleteProxyGroup(id: $id) {
      id
      name
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const ProxyGroups: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [proxyGroupList, setProxyGroupList] = useState<IProxyGroup[]>([]);

  const { loading, error, data } = useQuery(GET_PROXY_GROUPS)
  const [deleteProxyGroup] = useMutation(DELETE_PROXY_GROUP)

  useEffect(() => {
    if (data) {
      setProxyGroupList(data.proxyGroups)
    }
  }, [data])

  const handleRowClick = (proxyGroup: IProxyGroup) => {
    history.push(`/proxy-group/${proxyGroup.id}`);
  };

  const handleEditClick = (proxyGroup: IProxyGroup, e: React.MouseEvent) => {
    history.push(`/edit-proxy-group/${proxyGroup.id}`);
    e.stopPropagation();
  };

  const handleDeleteProxyGroup = async (proxyGroup: IProxyGroup) => {
    const { data, error } = await deleteProxyGroup({ variables: { id: proxyGroup.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newProxyGroupList = [...proxyGroupList];
      remove(newProxyGroupList, {
        id: proxyGroup.id
      })
      setProxyGroupList(newProxyGroupList);
      dispatch(sendSuccessNotification(`Proxy group "${data.deleteProxyGroup.name}" has been deleted successfully`));
    }
  };

  const getHeader = (isAdmin: boolean) => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    ...(isAdmin
      ? [
        {
          title: '',
          prop: 'buttons',
          sortable: false,
          filterable: false,
          cellProps: {
            className: () => 'pull-right',
          },
        },
      ]
      : []),
  ];

  const getBody = (groupList: IProxyGroup[], isAdmin: boolean) =>
    groupList.map((group) => ({
      ...group,
      ...(isAdmin
        ? {
          buttons: (
            <React.Fragment>
              <EditButton onClick={(e) => handleEditClick(group, e)} />{' '}
              <DeleteButton onClick={() => handleDeleteProxyGroup(group)} />
            </React.Fragment>
          ),
        }
        : {}),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Proxy Groups</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Proxy Groups</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader(isAdminAccount)}
                      tableBody={getBody(proxyGroupList, isAdminAccount)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                      onRowClick={handleRowClick}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProxyGroups;
