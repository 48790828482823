import React from 'react';

const ContentSectionHeader: React.FC = ({ children }) => (
  <header className="panel_header">
    <h2 className="title float-left">{children}</h2>
  </header>
);

const ContentSectionBody: React.FC = ({ children }) => (
  <div className="content-body">
    <div className="row">
      {children}
    </div>
  </div>
);

type IContentSection = React.FC & {
  Header: typeof ContentSectionHeader;
  Body: typeof ContentSectionBody;
};

const ContentSection: IContentSection = ({ children }) => (
  <section className="box">{children}</section>
);

ContentSection.Header = ContentSectionHeader;
ContentSection.Body = ContentSectionBody;

export default ContentSection;
