export enum Status {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export enum ErrorCode {
  UNKNOWN = 0x0,
  EMAIL_REQUIRED = 0x10000001,
  PASSWORD_REQUIRED = 0x10000002,
  PASSWORD_MISSMATCH = 0x10000003,
}
