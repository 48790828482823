import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  Container,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { selectAccountData } from '@selectors/account';
import { Messages } from 'components';
import UserDropdownMenu from '@components/common/Header/UserDropdownMenu';
import { messages } from 'variables/topbar';

const IMGDIR = process.env.REACT_APP_IMGDIR;

type HeaderProps = {
  navtype: string;
  location: Location;
};

const Header: React.FC<HeaderProps> = ({ navtype, location }) => {
  const [userddOpen, setUserddOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [messagesddOpen, setMessagesddOpen] = useState(false);

  const account = useSelector(selectAccountData);
  const sidebarToggleEl = useRef<HTMLButtonElement>(null);

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-toggle');
    if (sidebarToggleEl.current) {
      sidebarToggleEl.current.classList.toggle('toggled');
    }
  };

  const toggleGrid = () => {
    document.documentElement.classList.toggle('toggle-grid');
  };

  useEffect(() => {
    if (navtype === 'mini') {
      document.documentElement.classList.add('nav-toggle');
      if (sidebarToggleEl.current) {
        sidebarToggleEl.current.classList.add('toggled');
      }
    } else {
      document.documentElement.classList.remove('nav-toggle');
      if (sidebarToggleEl.current) {
        sidebarToggleEl.current.classList.remove('toggled');
      }
    }
  }, []);

  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-toggle') !== -1
    ) {
      document.documentElement.classList.toggle('nav-toggle');
      if (sidebarToggleEl.current) {
        sidebarToggleEl.current.classList.toggle('toggled');
      }
    }
  });

  if (!account) {
    return null;
  }

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      expand="lg"
      className={
        location.pathname.indexOf('full-screen-maps') !== -1
          ? 'navbar-absolute fixed-top'
          : 'navbar-absolute fixed-top '
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggleEl}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <i className="i-menu"></i>
            </button>
          </div>

          <NavbarBrand href="/">Maia Monitors</NavbarBrand>
        </div>

        <Collapse navbar className="navbar-right">
          <Nav navbar>
            <Dropdown
              nav
              isOpen={userddOpen}
              toggle={() => setUserddOpen(!userddOpen)}
              className="userdd"
            >
              <DropdownToggle caret nav title={account.email}>
                <img src={account.photo_url} alt="account-photo" className="avatar-image" />
                <span>{account.display_name}</span>
              </DropdownToggle>
              <UserDropdownMenu />
            </Dropdown>
          </Nav>
          <div className="screensize" onClick={() => toggleGrid()}></div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
