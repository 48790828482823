import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { History } from 'history';

import { useAuth0 } from '@auth0/auth0-react';
import { ClientContext } from "graphql-hooks";

import Loader from '@components/common/Loader/Loader';
import Notification from '@components/common/Notification/Notification';

import { selectAccountData, isAccountLoading } from '@selectors/account';
import { authorize, unauthorize } from '@actions/accountActions';

import indexRoutes from '@routes/index';

type AppProps = {
  history: History;
};

const App: React.FC<AppProps> = ({ history }) => {

  const client = useContext(ClientContext);

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const account = useSelector(selectAccountData);
  const accountLoading = useSelector(isAccountLoading);

  useEffect(
    () => {
      const ensureAndAuthenticateUser = async () => {
        if (isAuthenticated) {
          if (user) {
            const accessToken = await getAccessTokenSilently()  //todo: token doesn't include email :(
            client.setHeader('Authorization', `Bearer ${accessToken}`)
            dispatch(authorize(user, accessToken));
            return
          }
        }
        dispatch(unauthorize());
      }

      ensureAndAuthenticateUser()
    }, [isAuthenticated]
  );

  return (
    <>
      <Notification />
      <Router history={history}>
        {accountLoading || isLoading ? (
          <Loader />
        ) : (
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <prop.route path={prop.path} key={key} component={prop.component} account={account} exact={prop.exact} />
              );
            })}
          </Switch>
        )}
      </Router>
    </>
  );
};

export default App;
