import { combineReducers, CombinedState } from 'redux';
import { RootState } from './interface';

import notificationReducer from './notification/notificationReducer';
import accountReducer from './account/accountReducer';

import { ActionTypes, Actions } from '@actions/types';

const appReducer = combineReducers<RootState>({
  notification: notificationReducer,
  account: accountReducer,
});

const rootReducer = (state: RootState | undefined, action: Actions): CombinedState<RootState> => {
  if (action.type === ActionTypes.RESET_ROOT_STATE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

export * from './interface';
