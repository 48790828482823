import React from 'react';
import { Spinner } from 'reactstrap';

export type LoaderProps = {
  color?: string;
  size?: number;
};

//todo: move styles to scss file
const Loader: React.FC<LoaderProps> = ({ color = 'primary', size = 5 }) => (
  <div
    style={{
      height: '100%',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner color={color} style={{ width: `${size}rem`, height: `${size}rem` }} />
  </div>
);

export default Loader;
