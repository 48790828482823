/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, ChangeEvent, FocusEvent } from 'react';
import { Field } from 'formik';
import { Input } from 'reactstrap';
import get from 'lodash/get';

import useFormContext from '@hooks/useFormContext';

import { InputOption, BaseFormComponentProps } from '@models/forms';

import { renderFormError, renderFormLabel } from '@utils/forms';

export interface BaseSelectProps {
    disabled?: boolean;
    error?: boolean;
    invalid?: boolean;
    focused?: boolean;
    name?: string;
    onBlur?(e: FocusEvent<HTMLInputElement>): void;
    onChange?(e: ChangeEvent<HTMLInputElement>): void;
    placeholder?: string;
    options: InputOption[];
    value: string;
}

type ComponentProps = BaseFormComponentProps<BaseSelectProps>;

const FormSelect: FC<ComponentProps> = ({
    hideErrorBlock = false,
    hideErrorText = false,
    label,
    ...baseSelectProps
}) => {
    const { name, options, placeholder, onChange } = baseSelectProps;
    const { errors, touched, setFieldValue } = useFormContext();

    const error = !!get(errors, name) && !!get(touched, name);

    return (
        <div className="form-row">
            <div className="form-group col-md-12">
                {renderFormLabel(name, label)}
                <Field
                    {...baseSelectProps}
                    as={Input}
                    type="select"
                    invalid={error}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(name, e.target.value)
                        if (onChange) onChange(e);
                    }}
                >
                    <option key="formSelectOption_default" value="">{placeholder}</option>
                    {options.map((option) => (<option key={`formSelectOption_${option.value}`} value={option.value}>{option.label}</option>))}
                </Field>
                {hideErrorBlock ? null : renderFormError(name, hideErrorText)}
            </div>
        </div>
    );
};

export default FormSelect;
