import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  className?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  className,
}) => (
  <div>
    <Modal isOpen={isOpen} className={className}>
      <ModalBody>Are you sure you want to delete this item?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          Delete
        </Button>{' '}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </div>
);

export default DeleteConfirmationModal;
