import React from 'react';
import { Container } from 'reactstrap';

import maiaMonitorsLogo from '@assets/img/maia-monitors.svg';

import twitterLogo from '@assets/img/twitter.svg';
import discordLogo from '@assets/img/discord.svg';

interface FooterProps {
  fluid?: boolean;
  isPublic?: boolean;
}

const Footer: React.FC<FooterProps> = ({ fluid = true, isPublic = false }) => {
  return (
    <Container className="footer footer-default" fluid={fluid ? true : false}>
      <div className="top">
        <img src={maiaMonitorsLogo} alt="Maia Monitors" width="176" height="66" />
        {isPublic && (<a className="dashboardButton" href="/products">Dashboard</a>)}
      </div>
      <div className="bottom">
        <ul>
          <li>
            <a href="https://twitter.com/stellara_io" target="_blank">
              <img src={twitterLogo} alt="Maia Twitter" width="24" height="24" />
            </a>
          </li>
          <li>
            <a href="https://discord.gg/eKemCxhGAX" target="_blank">
              <img src={discordLogo} alt="Maia Discord" width="24" height="24" />
            </a>
          </li>
          <li>
            <a href="/terms">
              Terms
            </a>
          </li>
          <li>
            <a href="/privacy">
              Privacy
            </a>
          </li>
        </ul>
        <span className="copyright">© Maia Monitors 2021</span>
      </div>
    </Container>
  );
};
export default Footer;
