import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from "graphql-hooks";

import ContentSection from '@components/common/ContentSection/ContentSection';
import ManageProducts from '@components/release/ManageProducts';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const GET_PRODUCTS = `
  query GetProducts($id: ID!) {
    products {
      id
      sku
      title
    }
    group(id: $id) {
      id
      releaseProducts {
        id
        product {
          id
          sku
          title
        }
        preset
      }
    }
  }`;

const CREATE_RELEASE_PRODUCT = `
  mutation DeleteReleaseProduct(
    $groupId: ID!,
    $productId: ID!,
    $preset: String
  ) {
    createReleaseProduct(
      data: {
        groupId: $groupId,
        productId: $productId,
        preset: $preset
      }
    )
  }`;

const MODIFY_RELEASE_PRODUCT = `
  mutation ModifyReleaseProduct(
    $id: ID!,
    $preset: String
  ) {
    modifyReleaseProduct(
      id: $id,
      data: {
        preset: $preset
      }
    )
  }`;

const DELETE_RELEASE_PRODUCT = `
  mutation DeleteReleaseProduct(
    $id: ID!
  ) {
    deleteReleaseProduct(
      id: $id
    )
  }`;

type ParamTypes = {
  releaseId: string;
};

const ManageReleaseProducts: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_PRODUCTS, { variables: { id: params.releaseId } })
  const [createReleaseProduct] = useMutation(CREATE_RELEASE_PRODUCT)
  const [modifyReleaseProduct] = useMutation(MODIFY_RELEASE_PRODUCT)
  const [deleteReleaseProduct] = useMutation(DELETE_RELEASE_PRODUCT)

  const handleDelete = async (id: number) => {
    const { data, error } = await deleteReleaseProduct(
      {
        variables: {
          id
        }
      }
    )

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Release product has been deleted`));
      history.push(`/release-products/${params.releaseId}`);
    }
  };

  const handleSubmit = async (values: GenericFormValues, id?: number) => {
    const { data, error } = id ? await modifyReleaseProduct(
      {
        variables: {
          id: id,
          preset: values.preset,
        }
      }
    ) : await createReleaseProduct(
      {
        variables: {
          groupId: params.releaseId,
          productId: values.productId,
          preset: values.preset,
        }
      }
    );

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Release product has been ${id ? 'modified' : 'created'} successfully`));
      history.push(`/release-products/${params.releaseId}`);
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Release Products</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>Product Management</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <ManageProducts
                      vendorId={data.group.id}
                      products={data.group.releaseProducts.map((releaseData: any) => ({
                        releaseProductId: releaseData.id,
                        id: releaseData.product.id,
                        sku: releaseData.product.sku,
                        title: releaseData.product.title,
                        preset: releaseData.preset,
                      }))}
                      onSubmit={handleSubmit}
                      onDelete={handleDelete} />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ManageReleaseProducts;
