import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useManualQuery } from 'graphql-hooks';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import { Form, FormInput, FormSelect, FormButton } from '@components/forms';

import { InputOption, GenericFormValues } from '@models/forms';
import { IGroup } from '@models/group';
import { IProxyGroup } from '@models/proxyGroup';
import { regions } from '@variables/region'

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_PROXY_GROUPS = `
query GetProxyGroups {
    proxyGroups {
        id
        name
    }
}`;

const GET_GROUPS = `
query GetGroups($region: String!, $vendor: Boolean!) {
  groups(region: $region, vendor: $vendor) {
    id
    name
  }
}`;

type AddGroupFormProps = {
  onSubmit(values: GenericFormValues): void | Promise<any>;
};

const AddGroupForm: React.FC<AddGroupFormProps> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [regionGroupNames, setRegionGroupNames] = useState<string[]>([]);
  const [regionGroupError, setRegionGroupError] = useState<any>();

  const { loading, error, data } = useQuery(GET_PROXY_GROUPS)
  const [getRegionGroups] = useManualQuery(GET_GROUPS);

  const regionOptions: InputOption[] = regions.map((region: any) => ({ value: region, label: region }));

  const proxyGroupOptions: InputOption[] = useMemo(() => data ? data.proxyGroups.map((proxyGroup: IProxyGroup) => ({ value: proxyGroup.id, label: proxyGroup.name })) : [], [data]);

  const getRegionGroupNames = async (region: string) => {
    const { data, error } = await getRegionGroups({
      variables: { region, vendor: true }
    });

    if (error) {
      setRegionGroupError(error)
    } else if (data) setRegionGroupNames(data.groups.map((group: IGroup) => group.name));
  }

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required()
        .test('group-fetch-error', 'Error: cannot retrieve names', (name) => {
          return !regionGroupError;
        })
        .test('group-already-exists', 'This name already exists', (name) => {
          if (name) {
            return !regionGroupNames.includes(name);
          }
          return false;
        }),
      region: yup.string().required(),
      instances: yup.number().nullable(),
      delay: yup.number().nullable(),
      monitorProxyGroupId: yup.number(),
      checkoutProxyGroupId: yup.number(),
    })
  }, [regionGroupNames, regionGroupError]);

  const initialValues = {
    name: '',
    region: '',
    instances: '',
    delay: '',
    monitorProxyGroupId: '',
    checkoutProxyGroupId: '',
  };

  const handleSubmit = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSubmit(values);

    setSubmitting(false);
  }

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      <FormInput name="name" label="Name" type="text" placeholder="Enter name" maxLength={128} />
      <FormSelect name="region" label="Region" options={regionOptions} placeholder="Select region" onChange={(e) => getRegionGroupNames(e.target.value)} />
      <FormInput name="instances" label="Instances" type="number" placeholder="Enter instances" maxLength={9} />
      <FormInput name="delay" label="Delay" type="number" placeholder="Enter delay" maxLength={9} />
      <FormSelect name="monitorProxyGroupId" label="Monitor Proxy Group" options={proxyGroupOptions} placeholder="Select monitor proxy group" />
      <FormSelect name="checkoutProxyGroupId" label="Checkout Proxy Group" options={proxyGroupOptions} placeholder="Select checkout proxy group" />
      <FormButton type="submit" submitting={submitting} ignoreDirty>Add</FormButton>
    </Form>
  )
}

export default AddGroupForm;
