import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'graphql-hooks';
import { Row, Col } from 'reactstrap';
import { concat } from 'lodash';

import EditProductForm from '@components/product/EditProductForm';
import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

const GET_PRODUCT = `
query GetProduct($id: ID!) {
  product(id: $id) {
    id
    title
    sku
    url
    thumbnail
    offer_id
    region
    active
    private
    test
    groups {
      id
      vendor
    }
  }
}`;

const MODIFY_PRODUCT = `
mutation ModifyProduct(
    $id: ID!,
    $title: String!,
    $sku: String!,
    $url: String!,
    $thumbnail: String!,
    $offerId: String,
    $active: Boolean!,
    $private: Boolean!,
    $test: Boolean!,
  ) {
    modifyProduct(
        id: $id
        data: {
          title: $title, 
          sku: $sku, 
          url: $url, 
          thumbnail: $thumbnail, 
          offer_id: $offerId,
          active: $active, 
          private: $private,
          test: $test,
        }
    ) {
      id
      title
    }
  }`;

const MODIFY_PRODUCT_GROUPS = `
  mutation ModifyProductGroups(
    $productId: ID!,
    $groupIds: [ID]!
    ) {
      modifyProductGroups(productId: $productId, groupIds: $groupIds)
    }
`;

type ParamTypes = {
  productId: string;
};

const EditProduct: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_PRODUCT, { variables: { id: params.productId } })
  const [modifyProduct] = useMutation(MODIFY_PRODUCT)
  const [modifyProductGroups] = useMutation(MODIFY_PRODUCT_GROUPS)

  const handleSubmit = async (values: any) => {
    const { data: modifyProductData, error: modifyProductError } = await modifyProduct(
      {
        variables: {
          id: params.productId,
          ...values,
        }
      }
    );

    if (error) {
      dispatch(sendErrorNotification(modifyProductError));
      return;
    }

    const { data: modifyProductGroupsData, error: modifyProductGroupsError } = await modifyProductGroups(
      {
        variables: {
          productId: modifyProductData.modifyProduct.id,
          groupIds: concat(values.vendors, values.groups)
        }
      }
    )

    if (modifyProductGroupsError) {
      dispatch(sendErrorNotification(modifyProductGroupsError));
      return
    }

    dispatch(sendSuccessNotification(`Product "${modifyProductData.modifyProduct.title}" has been updated successfully`));
    history.push('/products');
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Edit Product</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Product information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <EditProductForm product={data.product} onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditProduct;
