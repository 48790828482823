import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { isAccountLoading } from '@selectors/account';

import { Loader } from 'components';

const UserDropdownMenu: React.FC = () => {
  const { logout } = useAuth0();

  const isLoading = useSelector(isAccountLoading);

  const handleSignout = () => {
    logout({
      returnTo: window.location.origin,
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DropdownMenu right>
      {/* <DropdownItem tag={NavLink} to="/user-profile">todo
        <i className="i-user"></i> Profile
      </DropdownItem> */}
      <DropdownItem tag="a" onClick={handleSignout}>
        <i className="i-lock"></i> Logout
      </DropdownItem>
    </DropdownMenu>
  );
};

export default UserDropdownMenu;
