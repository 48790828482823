import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Form, FormInput, FormButton, FormSelect } from '@components/forms';

import { GenericFormValues, InputOption } from '@models/forms';
import { IAutomationGroup } from '@models/automation';

import yup from '@utils/yup';

interface MonitorModalProps {
  isOpen: boolean;
  onSave(values: GenericFormValues): void | Promise<any>;
  onCancel: () => void;
  className?: string;
  monitor?: IAutomationGroup;
}

const MonitorModal: React.FC<MonitorModalProps> = ({
  isOpen,
  onSave,
  onCancel,
  className,
  monitor,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const runOptions: InputOption[] = [{ value: "true", label: "Yes" }, { value: "false", label: "No" }];

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    isDefaultRun: yup.string().required(),
    resources: yup.string().required(),
  });

  const initialValues = {
    name: monitor ? monitor.name : '',
    isDefaultRun: monitor ? monitor.is_default_run : 'false',
    resources: monitor && monitor.resources ? monitor.resources.join('\n') : '',
  };

  const handleSave = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSave(values);

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize>
      <Modal isOpen={isOpen} className={className}>
      <ModalHeader>{monitor ? "Edit" : "Add"} Monitor</ModalHeader>
        <ModalBody>
          <FormInput name="name" label="Name" type="text" placeholder="Enter name" maxLength={128} />
          <FormSelect name="isDefaultRun" label="Is Default Run" options={runOptions} />
          <FormInput name="resources" label="Keywords / URLs" type="textarea" rows={6} placeholder="Enter keywords or URLs" />
        </ModalBody>
        <ModalFooter>
          <FormButton type="button" color="secondary" onClick={onCancel}>Cancel</FormButton>
          <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
        </ModalFooter>
      </Modal>
    </Form>
  );
};

export default MonitorModal;
