import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NotificationAlert from 'react-notification-alert';
import { NotificationPlace, NotificationType } from '@components/common/Notification/enum';

import { selectNotificationData } from '@selectors/notification';

const Notification: React.FC = () => {
  const notifyEl = useRef(NotificationAlert);
  const notificationData = useSelector(selectNotificationData);

  useEffect(() => {
    if (notificationData) {
      notifyEl &&
        notifyEl.current.notificationAlert({
          type: notificationData.type as NotificationType,
          place: NotificationPlace.TOP_CENTER,
          message: <div style={{ whiteSpace: 'pre-wrap' }}>{notificationData.message}</div>,
          autoDismiss: 5
        });
    }
  }, [notificationData]);

  return (
    <div style={{ position: 'fixed', zIndex: 9999 }}>
      <NotificationAlert ref={notifyEl} />
    </div>
  );
};

export { NotificationPlace, NotificationType };
export default Notification;
