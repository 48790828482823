import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery, useMutation } from "graphql-hooks";

import EditDropEventForm from '@components/dropEvent/EditDropEventForm';
import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const GET_DROP_EVENT = `
  query GetDropEvent($id: ID!) {
    dropEvent(id: $id) {
      id
      name
      group {
        id
        name
      }
      products {
        sku
      }
      start_date
      end_date
    }
  }`;

const UPDATE_DROP_EVENT = `
  mutation UpdateDropEvent($id: ID!, $name: String, $startDate: Date, $endDate: Date) {
    updateDropEvent(id: $id, data: {name: $name, startDate: $startDate, endDate: $endDate}) {
      id
      name
    }
  }`;

type ParamTypes = {
  dropEventId: string;
};

const EditDropEvent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_DROP_EVENT, { 
    variables: { id: params.dropEventId }
   });
  const [updateDropEvent] = useMutation(UPDATE_DROP_EVENT);

  const handleSubmit = async (values: GenericFormValues) => {
    const { data, error } = await updateDropEvent({
      variables: {
        id: params.dropEventId,
        ...values 
      }
    })

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Drop event "${data.updateDropEvent.name}" has been updated successfully`));
      history.push('/drop-events');
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Edit Drop Event</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>ID: {data.dropEvent.id} - {data.dropEvent.group.name}: {data.dropEvent.products.map(({sku}: {sku: string}) => sku).join(', ')}</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <EditDropEventForm dropEvent={data.dropEvent} onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditDropEvent;
