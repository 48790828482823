import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useQuery } from 'graphql-hooks';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import { Form, FormInput, FormButton, FormSelect } from '@components/forms';

import { GenericFormValues } from '@models/forms';
import { IProduct, IReleaseProduct } from '@models/product';
import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_PRODUCTS_BY_ID = `
  query GetProductsById($id: ID!) {
    group(id: $id) {
      products {
        id
        sku
        title
      }
    }
  }`;

interface ProductModalProps {
  isOpen: boolean;
  onSave(values: GenericFormValues): void | Promise<any>;
  onCancel: () => void;
  className?: string;
  vendorId: string;
  product?: IReleaseProduct;
}

const ProductModal: React.FC<ProductModalProps> = ({
  isOpen,
  onSave,
  onCancel,
  className,
  vendorId,
  product,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { loading, error, data } = useQuery(GET_PRODUCTS_BY_ID, { variables: { id: vendorId } })

  const validationSchema = yup.object().shape({
    productId: yup.string().required(),
    preset: yup.string().required().test('is-valid-json', 'JSON is not valid', (preset) => {
      if (!preset) return false;

      try {
        JSON.parse(preset);
      } catch (e) {
        return false;
      }

      return true;
    }),
  });

  const initialValues = {
    productId: product ? product.id : '',
    preset: product ? product.preset : '',
  };

  const handleSave = async (values: GenericFormValues) => {
    setSubmitting(true);

    await onSave(values);

    setSubmitting(false);
  }

  if (error) {
    dispatch(sendErrorNotification(error));
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize>
      <Modal isOpen={isOpen} className={className}>
        <ModalHeader>{product ? "Edit" : "Add"} Product</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          {error && <Unavailable />}
          {data &&
            (<>
              <FormSelect
                name="productId"
                label="Product"
                options={
                  data.group.products.map((product: IProduct) => ({
                    value: product.id,
                    label: `${product.sku}: ${product.title}`
                  }))
                }
                placeholder="Select product"
                disabled={!!product} />
              <FormInput name="preset" label="Preset" type="textarea" rows={10} placeholder="Enter JSON preset" />
            </>)
          }
        </ModalBody>
        <ModalFooter>
          <FormButton type="button" color="secondary" onClick={onCancel}>Cancel</FormButton>
          {data && <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>}
        </ModalFooter>
      </Modal>
    </Form>
  );
};

export default ProductModal;
