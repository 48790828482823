import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useQuery, useMutation } from "graphql-hooks";

import EditProxyGroupForm from '@components/proxy/EditProxyGroupForm';
import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const GET_PROXY_GROUP = `
  query GetProxyGroup($id: ID!) {
    proxyGroup(id: $id) {
      id
      name
      proxies {
        id
        host
        port
        username
        password
      }
    }
  }`;

const MODIFY_PROXY_GROUP = `
  mutation ModifyProxyGroup($id: ID!, $name: String!, $proxies: [ProxyInput!]) {
    modifyProxyGroup(id: $id, data: {name: $name, proxies: $proxies}) {
      id
      name
    }
  }`;

type ParamTypes = {
  proxyGroupId: string;
};

const EditProxyGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, error, data } = useQuery(GET_PROXY_GROUP, { variables: { id: params.proxyGroupId } })
  const [modifyProxyGroup] = useMutation(MODIFY_PROXY_GROUP)

  const handleSubmit = async (values: GenericFormValues) => {
    let proxyArray: any[] = [];
    if (values.proxies) {
      proxyArray = values.proxies.toString().trim().split(/\n/).map((proxyLine: any) => {
        const proxy = proxyLine.trim().split(':');
        return {
          host: proxy[0],
          port: proxy[1],
          username: proxy[2] || undefined,
          password: proxy[3] || undefined,
        }
      })
    }

    const { data, error } = await modifyProxyGroup({
      variables: {
        id: params.proxyGroupId,
        name: values.name,
        proxies: proxyArray
      }
    })

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Proxy group "${data.modifyProxyGroup.name}" has been modified successfully`));
      history.push('/proxy-groups');
    }
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  let proxyGroup = {
    id: data.proxyGroup.id,
    name: data.proxyGroup.name,
    proxies: data.proxyGroup.proxies.reduce((proxiesText: string, proxy: any) => {
      let prx = `${proxy.host}:${proxy.port}`

      if (proxy.username) prx += `:${proxy.username}`;
      if (proxy.password) prx += `:${proxy.password}`;

      return proxiesText + prx + '\r\n'
    }, '')
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Edit Proxy Group</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>ID: {params.proxyGroupId} - {data.proxyGroup.name}</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <EditProxyGroupForm proxyGroup={proxyGroup} onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditProxyGroup;
