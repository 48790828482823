import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { useDispatch } from 'react-redux';
import { useQuery } from "graphql-hooks";
import moment from 'moment';

import ContentSection from '@components/common/ContentSection/ContentSection';
import Loader from '@components/common/Loader/Loader';

import { sendErrorNotification } from '@actions/notificationActions';

import { IInvoice } from '@models/invoice';

const GET_PAYMENTS = `
  query GetCustomerPayments {
    user {
      payments {
        id
        object
        account_country
        account_name
        amount_due
        amount_paid
        amount_remaining
        created
        currency
        customer
        paid
        period_end
        period_start
        subscription
        subtotal
        tax
        total
      }
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const PaymentList: React.FC = () => {
  const dispatch = useDispatch();

  const [paymentList, setPaymentList] = useState<IInvoice[]>([]);

  const { loading, error, data } = useQuery(GET_PAYMENTS)

  useEffect(() => {
    if (data) {
      setPaymentList(data.user.payments || [])
    }
  }, [data])

  const getHeader = () => [
    { title: 'Amount', prop: 'total', sortable: true, filterable: false },
    { title: 'Period', prop: 'period', sortable: true, filterable: false },
    { title: 'Description', prop: 'description', sortable: true, filterable: false },
    { title: 'Date', prop: 'date', sortable: true, filterable: false },
  ];

  const getBody = (paymentList: IInvoice[]) =>
    paymentList.map((payment) => ({
      total: <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(payment.total / 100)}<Badge color="success">Succeeded</Badge></>,
      period: `${moment(payment.period_start * 1000).format('ll')} - ${moment(payment.period_start * 1000).add(1, 'M').format('ll')}`,
      description: 'Payment for Basic membership',
      date: moment(payment.created * 1000).format('lll'),
    }));

  if (error) {
    dispatch(sendErrorNotification(error));
  }

  return (
    <div className="col-xl-12">
      <ContentSection>
        <ContentSection.Header>Payments</ContentSection.Header>
        <ContentSection.Body>
          <div className="col-lg-12 dt-disp">
            {loading ? (<Loader />) : (
              <Datatable
                tableHeaders={getHeader()}
                tableBody={getBody(paymentList)}
                classes={classes}
                rowsPerPage={25}
                rowsPerPageOption={[25, 50, 100, 200]}
                initialSort={{ prop: 'id', isAscending: true }}
                labels={customLabels}
              />
            )}
          </div>
        </ContentSection.Body>
      </ContentSection>
    </div>
  );
};

export default PaymentList;
