import React from 'react';
import { HashLink } from 'react-router-hash-link';

import maiaLogo from '@assets/img/maia-logo.png';

const HeaderPublic: React.FC = () => {
  return (
    <header className="navbar-public">
      <img className="maiaLogo" src={maiaLogo} alt="Maia Monitors 2021" width="50" height="50" />
      <ul>
        <li><a href="/">Home</a></li>
        <li><HashLink to="/#features">Features</HashLink></li>
        <li><HashLink to="/#pricing">Pricing</HashLink></li>
        <li><a href="/products" className="primary">Dashboard</a></li>
      </ul>
    </header>
  );
};

export default HeaderPublic;
