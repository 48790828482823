import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { History } from 'history';
import { selectAccountData } from '@selectors/account';
import { Header, Footer, SideBar } from 'components';
import ecommerceRoutes, { IEcommerceRoute } from 'routes/ecommerce';
import { navWidth } from '@variables/settings';

type EcommerceLayoutProps = {
  history: History;
  location: Location;
};

const EcommerceLayout: React.FC<EcommerceLayoutProps> = (props) => {
  let mainPanel: Element | null = null;
  const account = useSelector(selectAccountData);

  useEffect(() => {
    if (props.history.action === 'PUSH') {
      mainPanel && (mainPanel.scrollTop = 0);
      document.scrollingElement && (document.scrollingElement.scrollTop = 0);
    }
  });

  const renderRoutes = (routes: IEcommerceRoute[]): any => {
    return routes.map((prop, key) => {
      if (prop.children) {
        return renderRoutes(prop.children);
      }

      return (
        <prop.route
          exact
          account={account}
          path={prop.path}
          roles={prop.roles}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  return (
    <div
      className="wrapper"
      data-menu="black"
      data-menutype="dark"
      data-topbar="dark"
      data-topbartype="colored"
    >
      <Header {...props} navtype={navWidth} />
      <SideBar {...props} routes={ecommerceRoutes} />
      <div
        className="main-panel"
        ref={(panel) => {
          mainPanel = panel;
        }}
      >
        <Switch>{renderRoutes(ecommerceRoutes)}</Switch>
        <Footer />
      </div>
    </div>
  );
};

export default EcommerceLayout;
