import { createSelector } from 'reselect';
import { RootState } from '@reducers/interface';
import { AccountState } from '@reducers/account/interfaces';
import { Status } from '@reducers/enum';
import { UserRole } from '@models/user';

const accountSelector = (state: RootState): AccountState => state.account;

export const selectAccountData = createSelector(
  accountSelector,
  (account: AccountState) => account.data,
);

export const selectIsAdminAccount = createSelector(
  accountSelector,
  (account: AccountState) => (account.data || {}).role === UserRole.Administrator,
);

export const selectIsCustomerAccount = createSelector(
  accountSelector,
  (account: AccountState) => (account.data || {}).role === UserRole.Customer,
);

export const selectAccountError = createSelector(accountSelector, (account: AccountState) =>
  account.status === Status.ERROR ? account.error : null,
);

export const isAccountLoading = createSelector(
  accountSelector,
  (account: AccountState) => account.status === Status.LOADING,
);
