import { Dispatch } from 'redux';
import { AppThunk } from '@reducers/index';

import { ActionTypes } from '@actions/types';
import { INotification } from '@models/notification';
import { NotificationType } from '@components/common/Notification/enum';

import { SendNotificationAction } from './interfaces';
import { formatError } from '@utils/errors';

export const sendNotification = (payload: INotification): SendNotificationAction => ({
  type: ActionTypes.SEND_NOTIFICATION,
  payload,
});

/* thunk */
export const sendErrorNotification = (error: any): AppThunk => async (dispatch: Dispatch) => {
  let notification: INotification = {
    type: NotificationType.ERROR,
    message: formatError(error)
  };
  dispatch<SendNotificationAction>(sendNotification(notification));
};

export const sendSuccessNotification = (message: string): AppThunk => async (dispatch: Dispatch) => {
  let notification: INotification = {
    type: NotificationType.SUCCESS,
    message
  };
  dispatch<SendNotificationAction>(sendNotification(notification));
};

export const sendInfoNotification = (message: string): AppThunk => async (dispatch: Dispatch) => {
  let notification: INotification = {
    type: NotificationType.INFO,
    message
  };
  dispatch<SendNotificationAction>(sendNotification(notification));
};

export const sendWarningNotification = (message: string): AppThunk => async (dispatch: Dispatch) => {
  let notification: INotification = {
    type: NotificationType.WARNING,
    message
  };
  dispatch<SendNotificationAction>(sendNotification(notification));
};

export * from './interfaces';
