import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useQuery, useMutation } from "graphql-hooks";

import DiscordForm from '@components/settings/DiscordForm';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { GenericFormValues } from '@models/forms';

const GET_CURRENT_USER = `
  query GetUser {
    user {
      id
      discord_user_id
      discord_server_id
      discord_username
      discord_server_name
      discord_server_logo
      discord_server_color
      discord_server_statement
    }
  }`;

const MODIFY_CURRENT_USER = `
  mutation ModifyUser(
    $id: ID!, 
    $discordUserId: String, 
    $discordServerId: String, 
    $discordUsername: String, 
    $discordServerName: String, 
    $discordServerLogo: String,
    $discordServerColor: String,
    $discordServerStatement: String
    ) {
    modifyUser(
      id: $id, 
      data: {
        discord_user_id: $discordUserId,
        discord_server_id: $discordServerId,
        discord_username: $discordUsername,
        discord_server_name: $discordServerName,
        discord_server_logo: $discordServerLogo,
        discord_server_color: $discordServerColor,
        discord_server_statement: $discordServerStatement
      }) {
      id
    }
  }`;

const EcommerceSettings: React.FC = () => {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState<number>();
  
  const { loading, error, data } = useQuery(GET_CURRENT_USER)
  const [modifyUser] = useMutation(MODIFY_CURRENT_USER)

  useEffect(()=> {
    if (data && data.user){
      setUserId(data.user.id)
    }
  }, [data])

  const handleServerSubmit = async (values: GenericFormValues) => {
    const { data, error } = await modifyUser({
      variables: {
        id: userId,
        discordUserId: values.discordUserId,
        discordServerId: values.discordServerId,
        discordUsername: values.discordUsername,
        discordServerName: values.discordServerName,
        discordServerLogo: values.discordServerLogo,
        discordServerColor: values.discordServerColor,
        discordServerStatement: values.discordServerStatement,
      }
    })

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification(`Discord information has been modified successfully`));
    }
  }

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Settings</h1>
              </div>
            </div>
            <div className="row margin-0">
              <DiscordForm user={data.user} onSubmit={handleServerSubmit} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EcommerceSettings;
