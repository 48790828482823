import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';

class ChatGroups extends React.Component {
  render() {
    var chatgroupsList = [];
    for (var i = 0; i < this.props.chatgroups.length; i++) {
      chatgroupsList.push(
        <ListGroupItem key={i}>
          <div className="user-info groupname">
            <span data-status={this.props.chatgroups[i].color} className="profile-status"></span>
            <span className="name">{this.props.chatgroups[i].name}</span>
          </div>
        </ListGroupItem>,
      );
    }
    return (
      <div>
        <ListGroup>{chatgroupsList}</ListGroup>
      </div>
    );
  }
}

ChatGroups.propTypes = {
  chatgroups: PropTypes.arrayOf(PropTypes.object),
};

export default ChatGroups;
