import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import { isArray, isNumber, some } from 'lodash';

import Logo from '@components/common/Logo/Logo';

import { selectAccountData } from '@selectors/account';

import { IEcommerceRoute } from '@routes/ecommerce';
import { UserRole, IAccount } from '@models/user';

let ps: PerfectScrollbar;
const IMGDIR = process.env.REACT_APP_IMGDIR;

type SideBarProps = {
  routes: IEcommerceRoute[];
  location: Location;
};

const SideBar: React.FC<SideBarProps> = ({ routes, location }) => {
  const [openDropDown, setOpenDropDown] = useState('');
  const account = useSelector(selectAccountData);
  const sidebarEl = useRef<HTMLDivElement>(null);

  const handleOpenDropDown = (open: string) => {
    setOpenDropDown(openDropDown === open ? '' : open);
  };

  const activeRoute = (routeName: string | string[]) => {
    if (isArray(routeName)) {
      return some(routeName, (rn) => location.pathname.startsWith(rn)) ? 'active' : '';
    }
    return location.pathname === routeName ? 'active' : '';
  };

  const renderChildren = (children: IEcommerceRoute[]) => (
    <Nav>
      {children.map((child) => (
        <li key={child.href}>
          <NavLink
            to={String(child.href)}
            className="nav-link"
            activeClassName="active"
            isActive={(match, location) => {
              if (match && match.isExact) return location.pathname.indexOf(String(child.href)) === 0;
              return isArray(child.path) && some(child.path, (p) => location.pathname.startsWith(p));
            }}
          >
            <span>{child.name}</span>
          </NavLink>
        </li>
      ))}
    </Nav>
  );

  const isItemVisible = (item: IEcommerceRoute, account: IAccount) =>
    item.href && isNumber(account.role) && item.roles.indexOf(account.role) !== -1;

  useEffect(() => {
    if (sidebarEl.current && navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarEl.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (ps && navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  }, []);

  if (!account) {
    return null;
  }

  return (
    <div className="sidebar menubar" data-color="black">
      <Logo />
      <div className="sidebar-wrapper" ref={sidebarEl}>
        <div className="profile-info row" style={{ minHeight: 80 }}>
          <div className="profile-image col-4">
            <img src={account.photo_url} alt="account-avatar" className="img-fluid avatar-image" />
          </div>
          <div className="profile-details col-8">
            <h3>
              <Link
                to="#"
                // to="/user-profile"
                style={{
                  wordBreak: 'break-word',
                  display: 'inline-block',
                  whiteSpace: 'pre-line',
                }}
              >
                {account.display_name}
              </Link>
              <span className="profile-status online"></span>
            </h3>
            <p className="profile-title">{isNumber(account.role) ? UserRole[account.role] : ''}</p>
          </div>
        </div>

        <Nav className="navigation">
          {routes
            .filter((item) => isItemVisible(item, account))
            .map((prop, key) => {
              const children = (prop.children || []).filter((child) =>
                isItemVisible(child, account),
              );

              if (prop.type === 'dropdown' && children.length > 1) {
                const isSelected =
                  children.some(
                    (prop) => isArray(prop.path)
                      ? some(prop.path, (p) => location.pathname.startsWith(p))
                      : String(prop.path) === location.pathname,
                  );

                return (
                  <li
                    className={
                      (isSelected || openDropDown === prop.name ? 'active' : '') + ' nav-parent '
                    }
                    data-toggle="collapse"
                    key={key}
                  >
                    <a className="nav-link" onClick={() => handleOpenDropDown(prop.name)}>
                      <i className={'i-' + prop.icon}></i>
                      <p>{prop.name}</p>
                      <span className={'arrow i-arrow-left'}></span>
                    </a>
                    {renderChildren(children)}
                  </li>
                );
              }

              return (
                <li
                  className={activeRoute(prop.path) + ' nav-parent '}
                  key={key}
                  onClick={() => handleOpenDropDown(prop.name)}
                >
                  <NavLink to={String(prop.href)} className="nav-link" activeClassName="active" isActive={() => (true)}>
                    <i className={'i-' + prop.icon}></i>
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
