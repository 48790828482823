import { createSelector } from 'reselect';
import { RootState } from '@reducers/interface';

import { NotificationState } from '@reducers/notification/interfaces';

const notificationSelector = (state: RootState): NotificationState => state.notification;

export const selectNotificationData = createSelector(
  notificationSelector,
  (notification: NotificationState) => notification.data,
);
