export enum UserRole {
  Customer = 0,
  Administrator = 1,
  Service = 3,
  Machine = 4
}

export interface IUser {
  id: number;
  email?: string;
  auth0_id?: string;
  role?: UserRole;
  active?: boolean;
  discord_user_id?: number;
  discord_server_id?: number;
  discord_username?: string;
  discord_server_name?: string;
  discord_server_logo?: string;
  discord_server_color?: string;
  discord_server_statement?: string;
}

export interface IAccount extends IUser {
  display_name: string;
  email_verified: boolean;
  photo_url: string;
}
