/***********************************
    // Menu Style Color
    // black, white, teal, purple, red, pink, orange, transparent, image1, image2, image3, image4
***********************************/
const menuStyle = 'image1';

/***********************************
    // Menu Type
    // dark, light, colored
***********************************/
const menuType = 'light';

/***********************************
    // Top Bar Style Color
    // black, white, teal, purple, red, pink, orange, transparent, image1, image2, image3, image4
***********************************/
const topbarStyle = 'pink';

/***********************************
    // Top bar Type
    // dark, light, colored
***********************************/
const topbarType = 'colored';

/***********************************
    // Navigation Width
    // default, mini
***********************************/
const navWidth = 'default';

/***********************************
    // Chat Type
    // overlap, squeeze
***********************************/
const chatType = 'overlap';

/***********************************
    // Chat Width
    // closed, open
***********************************/
const chatWidth = 'closed';

export { menuStyle, topbarStyle, menuType, topbarType, navWidth, chatWidth, chatType };
