import { GraphQLClient } from 'graphql-request';

const getGraphqlApiInstance = (accessToken: string) =>
  new GraphQLClient(process.env.REACT_APP_API_MAIA_URL || '', {
    headers: {
      Authorization:
        `Bearer ${accessToken}`,
    },
    mode: 'no-cors'
  });

export { getGraphqlApiInstance };
