import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { filter, some, every } from 'lodash'
import { Row, Col, CustomInput, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from "graphql-hooks";

import { selectIsAdminAccount } from '@selectors/account';
import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';

import { sendErrorNotification } from '@actions/notificationActions';

import { IProduct } from '@models/product';
import { IGroup } from '@models/group';
import { useMemo } from 'react';

interface IProductGrid extends IProduct {
  isChecked: boolean;
}

const GET_REGION_FILTER = `
  query GetRegionFilter($region: String!) {
    vendors: groups(vendor: true, region: $region) {
      id
      name 
    }
    groups(vendor: false, region: $region) {
      id
      name 
    }
    products(region: $region) {
      id
      sku
      title
      groups {
        id
      }
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

type ManageProductsProps = {
  region: string;
  productIds: number[];
  onSubmit(productIds: number[]): void;
};

const ManageProducts: React.FC<ManageProductsProps> = ({ region, productIds, onSubmit }) => {
  const dispatch = useDispatch();

  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [showAllProducts, setShowAllProducts] = useState<boolean>(false);
  const [filterVendor, setFilterVendor] = useState<String>();
  const [filterGroup, setFilterGroup] = useState<String>();
  const [productList, setProductList] = useState<IProductGrid[]>([]);

  const { loading, error, data } = useQuery(GET_REGION_FILTER, { variables: { region } });

  const isAllProductsSelected = () => every(productList, { isChecked: true });

  const getSelectedProductIds = () => productList.filter((product: IProductGrid) => product.isChecked).map((product: IProductGrid) => product.id);

  const selectAllProducts = () => setProductList([...productList].map((product: IProductGrid) => ({ ...product, isChecked: !isAllProductsSelected() })));

  const selectProduct = (product: IProductGrid) => {
    let newProductList: IProductGrid[] = [];

    productList.forEach((p: IProductGrid) => {
      if (product.id === p.id) {
        newProductList.push({ ...p, isChecked: !p.isChecked })
      } else {
        newProductList.push(p)
      }
    })

    setProductList(newProductList)
  }

  useEffect(() => {
    if (data) {
      setProductList(data.products.map((product: IProduct) => ({ ...product, isChecked: some(productIds, (id) => id == product.id) })))
    }
  }, [data])

  const productListFiltered = useMemo(() => {
    let filtered: any = productList;

    if (filterVendor) {
      filtered = filter(filtered, { groups: [{ id: filterVendor }] });
    }

    if (filterGroup) {
      filtered = filter(filtered, { groups: [{ id: filterGroup }] });
    }

    if (!showAllProducts) {
      filtered = filter(filtered, { isChecked: true });
    }

    return filtered;
  }, [productList, filterVendor, filterGroup, showAllProducts]);

  const getHeader = (isAdmin: boolean) => [
    { headerCell: () => <input type="checkbox" onClick={() => selectAllProducts()} checked={isAllProductsSelected()} />, prop: 'check', sortable: false, filterable: false },
    { title: 'ID', prop: 'id', sortable: true, filterable: false },
    { title: 'SKU', prop: 'sku', sortable: true, filterable: false },
    { title: 'Title', prop: 'title', sortable: true, filterable: false },
  ];

  const getBody = (products: IProductGrid[], isAdmin: boolean) =>
    products.map((product) => ({
      check: <input type="checkbox" checked={product.isChecked} onClick={() => selectProduct(product)} />,
      ...product,
    }));

  const handleUpdateClick = async () => {
    setSubmitting(true);

    const selectedProductIds: number[] = getSelectedProductIds();

    await onSubmit(selectedProductIds);

    setSubmitting(false);
  };

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <Row>
        <Col xs={2} md={2}>
          <FormGroup>
            <Label for="showAllProducts">Show All</Label>
            <CustomInput
              id="showAllProducts"
              type="switch"
              checked={showAllProducts}
              onChange={(e) => setShowAllProducts(!showAllProducts)}
            />
          </FormGroup>
        </Col>
        <Col xs={3} md={3}>
          <FormGroup>
            <Label for="vendorFilter">Vendors</Label>
            <Input type="select" name="vendorFilter" id="vendorFilter" onChange={(e) => setFilterVendor(e.target.value)}>
              <option value="">All</option>
              {data.vendors.map((vendor: IGroup) => (<option value={vendor.id}>{vendor.name}</option>))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={3} md={3}>
          <FormGroup>
            <Label for="groupFilter">Groups</Label>
            <Input type="select" name="groupFilter" id="groupFilter" onChange={(e) => setFilterGroup(e.target.value)}>
              <option value="">All</option>
              {data.groups.map((group: IGroup) => (<option value={group.id}>{group.name}</option>))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          Total selected: {getSelectedProductIds().length}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Datatable
            tableHeaders={getHeader(isAdminAccount)}
            tableBody={getBody(productListFiltered, isAdminAccount)}
            classes={classes}
            initialSort={{ prop: 'id', isAscending: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <button
            disabled={submitting}
            onClick={handleUpdateClick}
            type="button"
            className="btn btn-primary">
            {submitting ? <Loader size={1} color="white" /> : 'Update'}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ManageProducts;
