import * as GlobalActions from '../globalActions';
import * as AccountActions from '../accountActions';
import * as NotificationActions from '../notificationActions';

export enum ActionTypes {
  RESET_ROOT_STATE = 'RESET_ROOT_STATE',

  AUTHORIZE_ACCOUNT_REQUEST = 'AUTHORIZE_ACCOUNT_REQUEST',
  AUTHORIZE_ACCOUNT_SUCCESS = 'AUTHORIZE_ACCOUNT_SUCCESS',
  AUTHORIZE_ACCOUNT_ERROR = 'AUTHORIZE_ACCOUNT_ERROR',

  UNAUTHORIZE_ACCOUNT_REQUEST = 'UNAUTHORIZE_ACCOUNT_REQUEST',
  UNAUTHORIZE_ACCOUNT_SUCCESS = 'UNAUTHORIZE_ACCOUNT_SUCCESS',
  UNAUTHORIZE_ACCOUNT_ERROR = 'UNAUTHORIZE_ACCOUNT_ERROR',

  SEND_NOTIFICATION = 'SEND_NOTIFICATION'
}

export type Actions =
  // Global actions
  | GlobalActions.ResetRootStateAction
  // Account actions
  | AccountActions.AuthorizeAccountRequestAction
  | AccountActions.AuthorizeAccountSuccessAction
  | AccountActions.AuthorizeAccountErrorAction
  | AccountActions.UnauthorizeAccountRequestAction
  | AccountActions.UnauthorizeAccountSuccessAction
  | AccountActions.UnauthorizeAccountErrorAction
    // Notification actions
  | NotificationActions.SendNotificationAction
