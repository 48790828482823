import React from 'react';
import { Row, Col, Alert } from 'reactstrap';

import { useParams } from 'react-router-dom';

import MembershipInfo from '@components/subscription/MembershipInfo';
import PaymentList from '@components/subscription/PaymentList';

type ParamTypes = {
  sessionId: string;
};

const Subscription: React.FC = () => {
  const { sessionId } = useParams<ParamTypes>();

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                {sessionId === "canceled" &&
                  <Alert color="warning">
                    Payment was canceled by user
                  </Alert>}
                {sessionId && sessionId !== "canceled" &&
                  <Alert color="success">
                    Payment successful
                  </Alert>}
              </div>
              <div className="float-left">
                <h1 className="title">Subscription</h1>
              </div>
            </div>
            <div className="row margin-0">
              <MembershipInfo />
            </div>
            <div className="row margin-0">
              <PaymentList />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Subscription;
