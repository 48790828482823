import React, { useState, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useManualQuery } from 'graphql-hooks';
import { some } from 'lodash';

import { Form, FormInput, FormSelect, FormCheck, FormMulti, FormButton } from '@components/forms';

import { InputOption, GenericFormValues } from '@models/forms';
import { IGroup } from '@models/group';
import { regions } from '@variables/region';

import { sendErrorNotification } from '@actions/notificationActions';

import yup from '@utils/yup';

const GET_REGION_GROUPS = `
query GetRegionGroups($region: String!) {
    vendors: groups(vendor: true, region: $region) {
      id
      name 
    }
    groups(vendor: false, region: $region) {
      id
      name 
    }
}`;

interface Props {
  onSubmit(values: GenericFormValues): void | Promise<any>;
}

const ProductForm: React.FC<Props> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [regionGroups, setRegionGroups] = useState<any>();

  const [getRegionGroups] = useManualQuery(GET_REGION_GROUPS)

  const regionOptions: InputOption[] = regions.map((region: any) => ({ value: region, label: region }));

  const vendorOptions: InputOption[] = useMemo(() => regionGroups ? regionGroups.vendors.map((vendor: IGroup) => ({ value: vendor.id, label: vendor.name })) : [], [regionGroups]);

  const groupOptions: InputOption[] = useMemo(() => regionGroups ? regionGroups.groups.map((group: IGroup) => ({ value: group.id, label: group.name })) : [], [regionGroups]);

  const validationSchema = yup.object().shape({
    title: yup.string().required(),
    sku: yup.string().required(),
    url: yup.string().required(),
    thumbnail: yup.string().required(),
    offerId: yup.string(),
    region: yup.string().required(),
    active: yup.boolean().required(),
    private: yup.boolean().required(),
    test: yup.boolean().required(),
    vendors: yup.array().test('select-vendor', 'Select at least one vendor', (vendors) => {
      if (!vendors) return false;
      if (!(regionGroups && regionGroups.vendors)) return false;

      for (const id of vendors) {
        if (some(regionGroups.vendors, { id })) return true;
      }

      return false;
    }),
    groups: yup.array(),
  });

  const initialValues = {
    title: '',
    sku: '',
    url: '',
    thumbnail: '',
    offerId: '',
    region: '',
    active: true,
    private: false,
    test: false,
    vendors: [],
    groups: [],
  };

  const fetchRegionGroups = async (region: string) => {
    const { data: regionGroupsData, error: regionGroupsError } = await getRegionGroups({
      variables: { region }
    });

    if (regionGroupsError) {
      dispatch(sendErrorNotification('Couldn\'t load region vendors and groups'));
      return
    }

    setRegionGroups(regionGroupsData);
  }

  const handleSubmit = async (values: any) => {
    setSubmitting(true);

    await onSubmit({
      ...values,
      vendors: regionGroups.vendors.filter((vendor: IGroup) => values.vendors.includes(vendor.id.toString())).map((vendor: IGroup) => vendor.id),
      groups: regionGroups.groups.filter((group: IGroup) => values.groups.includes(group.id.toString())).map((group: IGroup) => group.id),
    });

    setSubmitting(false);
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      <Row>
        <Col xs={6} md={6}>
          <FormInput name="title" label="Title" type="text" placeholder="Enter title" maxLength={256} />
          <FormInput name="sku" label="SKU" type="text" placeholder="Enter SKU" maxLength={128} />
          <FormInput name="url" label="URL" type="text" placeholder="Enter URL" maxLength={256} />
          <FormInput name="thumbnail" label="Thumbnail" type="text" placeholder="Enter thumbnail" maxLength={256} />
          <FormInput name="offerId" label="Offer Id" type="text" placeholder="Enter offer id" maxLength={256} />
          <FormCheck name="active" label="Is active" />
          <FormCheck name="private" label="Is private" />
          <FormCheck name="test" label="Is test product" />
        </Col>
        <Col xs={6} md={6}>
          <FormSelect name="region" label="Region" options={regionOptions} placeholder="Select region" onChange={(e) => { fetchRegionGroups(e.target.value) }} />
          <FormMulti name="vendors" label="Vendors" options={vendorOptions} />
          <FormMulti name="groups" label="Groups" options={groupOptions} />
        </Col>
      </Row>
      <FormButton type="submit" submitting={submitting} ignoreDirty>Save</FormButton>
    </Form>
  )
};

export default ProductForm;
