import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useManualQuery, useMutation } from "graphql-hooks";
import { Row, Col, Alert } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import AddChannelForm from '@components/channel/AddChannelForm';
import ContentSection from '@components/common/ContentSection/ContentSection';

import { selectAccountData } from '@selectors/account';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';
import { isActiveMembership } from '@utils/membership';

import { GenericFormValues } from '@models/forms';

const GET_USER_SUBSCRIPTION = `
  query GetUserSubscription {
    user {
      role
      subscription_active
      subscription_expiry
    }
  }
`;

const GET_USER_SETTINGS = `
  query GetUserSettings {
    user {
      discord_user_id
      discord_username
      discord_server_id
      discord_server_name
      discord_server_logo
      discord_server_color
      discord_server_statement
    }
  }
`;

const CREATE_CHANNEL = `
  mutation CreateChannel(
      $userId: ID!, 
      $name: String!, 
      $webhook: String!,
      $region: String!,
      $alias: Boolean!, 
      $groupId: ID,
    ) {
      createChannel(
        userId: $userId,
        data: {
          name: $name,
          webhook: $webhook,
          region: $region,
          alias: $alias,
          groupId: $groupId,
        }
    ) {
      channel {
        id
        name
      }
      error {
        message
      }
    }
  }`;

const AddChannel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const account = useSelector(selectAccountData);

  const [getUserSettings] = useManualQuery(GET_USER_SETTINGS)
  const [createChannel] = useMutation(CREATE_CHANNEL)

  const { data: userSubscriptionData, loading: userSubscriptionLoading, error: userSubscriptionError } = useQuery(GET_USER_SUBSCRIPTION)

  const handleSubmit = async (values: GenericFormValues) => {
    if (!account) {
      dispatch(sendErrorNotification('Account data doesn\'t exist'));
      return;
    }

    const { data: userData, error: userError } = await getUserSettings();

    if (userError) {
      dispatch(sendErrorNotification(userError));
      return;
    }

    if (
      !(userData.user.discord_user_id
        && userData.user.discord_username
        && userData.user.discord_server_id
        && userData.user.discord_server_name
        && userData.user.discord_server_logo
        && userData.user.discord_server_color
        && userData.user.discord_server_statement)
    ) {
      dispatch(sendErrorNotification('You must fill out all your information on the settings page'));
      return;
    }

    const { data, error } = await createChannel({
      variables: {
        userId: account.id,
        name: values.name,
        webhook: values.webhook,
        region: values.region,
        alias: !!values.groupId,
        groupId: !!values.groupId ? values.groupId : undefined,
      }
    })

    if (error) dispatch(sendErrorNotification(error));
    else if (data.createChannel.error) dispatch(sendErrorNotification(data.createChannel.error));
    else {
      dispatch(sendSuccessNotification(`Channel "${data.createChannel.channel.name}" has been added successfully`));
      history.push('/channels');
    }
  };

  if (userSubscriptionLoading) {
    return <Loader />;
  } else if (userSubscriptionError) {
    dispatch(sendErrorNotification(userSubscriptionError));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Channel</h1>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                <ContentSection>
                  <ContentSection.Header>Channel Information</ContentSection.Header>
                  <ContentSection.Body>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      {!isActiveMembership(userSubscriptionData.user) &&
                        <Alert color="primary">
                          You need to be member user in order to add channels. Buy membership <Link to="/subscription">here</Link>
                        </Alert>
                      }
                      <Alert color="info">
                        Before creating a channel
                        <ul>
                          <li>
                            Fill out all your information on the <Link to="/settings">settings</Link> page
                          </li>
                          <li>
                            Add the webhook verification bot to the server you added on the settings page using this <a target="_blank" href="https://discord.com/oauth2/authorize?client_id=855289549827080202&scope=bot&permissions=8">link</a>
                          </li>
                        </ul>
                      </Alert>
                      <AddChannelForm
                        disabledSubmit={!isActiveMembership(userSubscriptionData.user)}
                        onSubmit={handleSubmit} />
                    </div>
                  </ContentSection.Body>
                </ContentSection>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddChannel;
