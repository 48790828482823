import { ActionTypes, Actions } from '@actions/types';
import { NotificationState } from './interfaces';

export const initialState: NotificationState = {
  data: undefined,
};

export default (state: NotificationState = initialState, action: Actions): NotificationState => {
  switch (action.type) {
    case ActionTypes.SEND_NOTIFICATION:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export * from './interfaces';
