import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { useQuery } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { IconButton } from '@components/buttons';

import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IGroup } from '@models/group';

const GET_GROUPS = `
  query GetGroups($vendor: Boolean!) {
    groups(vendor: $vendor) {
      id
      name
      region
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Releases: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [groupList, setGroupList] = useState<IGroup[]>([]);

  const { loading, error, data } = useQuery(GET_GROUPS, { variables: { vendor: true } })

  useEffect(() => {
    if (data) {
      setGroupList(data.groups)
    }
  }, [data])

  const handleManageProducts = (groupId: IGroup['id']) => history.push(`/release-products/${groupId}`);

  const getHeader = () => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Region', prop: 'region', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    {
      title: '',
      prop: 'buttons',
      sortable: false,
      filterable: false,
      cellProps: {
        className: () => 'pull-right',
        style: { display: 'flex' },
      },
    },
  ];

  const getBody = (groupList: IGroup[]) =>
    groupList.map((group) => ({
      ...group,
      buttons: (
        <React.Fragment>
          <IconButton icon="fa-cubes" onClick={() => handleManageProducts(group.id)} />
        </React.Fragment>
      ),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Releases</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Releases</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader()}
                      tableBody={getBody(groupList)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Releases;
