import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Datatable from 'react-bs-datatable';
import { remove } from 'lodash'
import { useQuery, useMutation } from "graphql-hooks";
import { Row, Col } from 'reactstrap';

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import ContentSection from '@components/common/ContentSection/ContentSection';
import { IconButton, EditButton, DeleteButton } from '@components/buttons';

import { selectIsAdminAccount } from '@selectors/account';
import { sendSuccessNotification, sendErrorNotification } from '@actions/notificationActions';

import { IGroup } from '@models/group';

const GET_GROUPS = `
  query GetGroups($vendor: Boolean!) {
    groups(vendor: $vendor) {
      id
      name
      region
    }
  }`;

const DELETE_GROUP = `
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
      name
    }
  }`;

const classes = {
  table: 'table-striped table-hover',
};

const customLabels = {
  first: '<<',
  last: '>>',
  prev: '<',
  next: '>',
  show: 'Display ',
  entries: ' rows',
  noResults: 'There is no data to be displayed',
};

const Group: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdminAccount = useSelector(selectIsAdminAccount);

  const [groupList, setGroupList] = useState<IGroup[]>([]);
  const [deleteGroup] = useMutation(DELETE_GROUP)

  const { loading, error, data } = useQuery(GET_GROUPS, { variables: { vendor: false } })

  useEffect(() => {
    if (data) {
      setGroupList(data.groups)
    }
  }, [data])

  const handleManageProducts = (groupId: IGroup['id']) => history.push(`/group-products/${groupId}`);

  const handleEditClick = (category: IGroup, e: React.MouseEvent) => {
    history.push(`/edit-group/${category.id}`);
    e.stopPropagation();
  };

  const handleDeleteGroup = async (group: IGroup) => {
    const { data, error } = await deleteGroup({ variables: { id: group.id } })

    if (error) {
      dispatch(sendErrorNotification(error));
    } else {
      let newGroupList = [...groupList];
      remove(newGroupList, {
        id: group.id
      })
      setGroupList(newGroupList);
      dispatch(sendSuccessNotification(`Proxy group "${data.deleteGroup.name}" has been added successfully`));
    }
  };

  const getHeader = (isAdmin: boolean) => [
    { title: 'ID', prop: 'id', sortable: true, filterable: true },
    { title: 'Region', prop: 'region', sortable: true, filterable: true },
    { title: 'Name', prop: 'name', sortable: true, filterable: true },
    ...(isAdmin
      ? [
        {
          title: '',
          prop: 'buttons',
          sortable: false,
          filterable: false,
          cellProps: {
            className: () => 'pull-right',
            style: { display: 'flex' },
          },
        },
      ]
      : []),
  ];

  const getBody = (groupList: IGroup[], isAdmin: boolean) =>
    groupList.map((group) => ({
      ...group,
      ...(isAdmin
        ? {
          buttons: (
            <React.Fragment>
              <IconButton icon="fa-cubes" onClick={() => handleManageProducts(group.id)} />
              <EditButton onClick={(e) => handleEditClick(group, e)} />{' '}
              <DeleteButton onClick={() => handleDeleteGroup(group)} />
            </React.Fragment>
          ),
        }
        : {}),
    }));

  if (loading) {
    return <Loader />;
  } else if (error) {
    dispatch(sendErrorNotification(error));
    return <Unavailable />;
  }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Groups</h1>
              </div>
            </div>
            <div className="col-12">
              <ContentSection>
                <ContentSection.Header>All Groups</ContentSection.Header>
                <ContentSection.Body>
                  <div className="col-lg-12 dt-disp">
                    <Datatable
                      tableHeaders={getHeader(isAdminAccount)}
                      tableBody={getBody(groupList, isAdminAccount)}
                      classes={classes}
                      rowsPerPage={25}
                      rowsPerPageOption={[25, 50, 100, 200]}
                      initialSort={{ prop: 'id', isAscending: true }}
                      labels={customLabels}
                    />
                  </div>
                </ContentSection.Body>
              </ContentSection>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Group;
