import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from "graphql-hooks";
import moment from 'moment';
import { isEmpty } from 'lodash'

import Loader from '@components/common/Loader/Loader';
import Unavailable from '@components/common/Unavailable/Unavailable';
import CustomConfirmationModal from '@components/common/ConfirmationModal/CustomConfirmationModal';

import { sendErrorNotification, sendSuccessNotification } from '@actions/notificationActions';

const CREATE_CHECKOUT_SESSION = `
  mutation CreateCheckoutSession($origin: String!) {
    createCheckoutSession(data: { origin: $origin }) {
      id
      url
    }
  }`;

const CANCEL_MEMBERSHIP = `
  mutation CancelSubscription {
    cancelSubscription {
      id
    }
  }`;

const CREATE_PAYMENT_METHOD_PORTAL_SESSION = `
  mutation CreatePaymentMethodPortalSession($origin: String!) {
    createPaymentMethodPortalSession(data: { origin: $origin }) {
      id
      url
    }
  }`;

const GET_CUSTOMER_SUBSCRIPTION = `
  query GetCustomerSubscription {
    user {
      subscriptions {
        status
        next_invoice {
          amount_due
          next_payment_attempt
        }
        payment_method {
          card {
            brand
            exp_month
            exp_year
            last4
          }
        }
      }
    }
  }`;

const MembershipInfo: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION)
  const [cancelSubscription] = useMutation(CANCEL_MEMBERSHIP)
  const [createPaymentMethodPortalSession] = useMutation(CREATE_PAYMENT_METHOD_PORTAL_SESSION)

  const { loading, error, data } = useQuery(GET_CUSTOMER_SUBSCRIPTION)

  const handleCancelMembership = () => setShowConfirmDialog(true);

  const handleCancelMembershipButton = () => setShowConfirmDialog(false);

  const handleConfirmCancel = async () => {
    setShowConfirmDialog(false)

    const { data, error } = await cancelSubscription();

    if (error) dispatch(sendErrorNotification(error));
    else {
      dispatch(sendSuccessNotification("Membership has been canceled"));
      history.push('/subscription');
    }
  }

  const handleBuyMembership = async () => {
    const { data, error } = await createCheckoutSession({
      variables: {
        origin: location.origin
      }
    });

    if (error) dispatch(sendErrorNotification(error));
    else {
      window.location.replace(data.createCheckoutSession.url)
    }
  };

  const handleMembershipPaymentUpdate = async () => {
    const { data, error } = await createPaymentMethodPortalSession({
      variables: {
        origin: location.origin
      }
    });

    if (error) dispatch(sendErrorNotification(error));
    else {
      window.location.replace(data.createPaymentMethodPortalSession.url)
    }
  }

  if (error) {
    dispatch(sendErrorNotification(error));
  }

  return (
    <>
      <div className="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-12">
        <div className="db_box">
          {error && <Unavailable />}
          {loading && <Loader size={3} />}
          {data && 
            (<div className="box-subscription">
              <i className="i-diamond icon-primary icon"></i>
              <div className="info">
                <h2 className="title">
                  Maia Basic Membership
                  {!isEmpty(data.user.subscriptions) && data.user.subscriptions[0].status === 'active' && <Badge color="success">active</Badge>}
                </h2>
                {!isEmpty(data.user.subscriptions) &&
                  <span className="annotation">
                    Billing monthly • Next invoice on {moment(data.user.subscriptions[0].next_invoice.next_payment_attempt * 1000).format('D MMM')} for {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.user.subscriptions[0].next_invoice.amount_due / 100)}
                  </span>
                }
              </div>
              {!isEmpty(data.user.subscriptions) ?
                <button type="button" className="btn btn-primary" onClick={handleCancelMembership}>
                  Cancel
                </button> :
                <button type="button" className="btn btn-primary" onClick={handleBuyMembership}>
                  Buy
                </button>
              }
            </div>)}
        </div>
      </div>
      {!loading && !error && !isEmpty(data.user.subscriptions) &&
        <div className="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-12">
          <div className="db_box box-subscription">
            <i className="i-credit-card icon-primary icon"></i>
            <div className="info">
              <h2 className="title">Payment method</h2>
              <span className="annotation">{data.user.subscriptions[0].payment_method.card.brand} •••• {data.user.subscriptions[0].payment_method.card.last4} • Expires {data.user.subscriptions[0].payment_method.card.exp_month}/{data.user.subscriptions[0].payment_method.card.exp_year}</span>
            </div>
            <button type="button" className="btn btn-primary" onClick={handleMembershipPaymentUpdate}>
              Update
            </button>
          </div>
        </div>}
      <CustomConfirmationModal
        confirmationMessage="Please confirm that you about to cancel your subscription"
        isOpen={showConfirmDialog}
        onConfirm={handleConfirmCancel}
        onCancel={handleCancelMembershipButton}
      />
    </>
  );
};

export default MembershipInfo;
