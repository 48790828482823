import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import { FavContacts, AllContacts, ChatGroups } from 'components';
import { favContacts, allContacts, chatGroups } from 'variables/topbar';

var ps;

class ChatSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  componentDidMount() {
    if (this.props.chatwidth === 'open') {
      document.documentElement.classList.add('nav-toggle-chat');
    } else {
      document.documentElement.classList.remove('nav-toggle-chat');
    }

    if (this.props.chattype === 'squeeze') {
      document.documentElement.classList.add('nav-chat-squeeze');
    } else {
      document.documentElement.classList.remove('nav-chat-squeeze');
    }

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.chatbar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (ps && navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar chatbar">
        <div className="sidebar-wrapper" ref="chatbar">
          <Form className="chat-search">
            <FormGroup>
              <Input type="text" name="search" id="chatsearch" placeholder="Search" />
            </FormGroup>
          </Form>
          <h4 className="group-head">Groups</h4>
          <ChatGroups chatgroups={chatGroups} />
          <h4 className="group-head">Favourites</h4>
          <FavContacts favcontacts={favContacts} />
          <h4 className="group-head">All Contacts</h4>
          <AllContacts allcontacts={allContacts} />
        </div>
      </div>
    );
  }
}

export default ChatSideBar;
