import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface CustomConfirmationModalProps {
  isOpen: boolean;
  confirmationMessage: string;
  onConfirm: () => void;
  onCancel: () => void;
  className?: string;
}

const CustomConfirmationModal: React.FC<CustomConfirmationModalProps> = ({
  isOpen,
  confirmationMessage,
  onConfirm,
  onCancel,
  className,
}) => (
  <div>
    <Modal isOpen={isOpen} className={className}>
      <ModalBody>{confirmationMessage}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>{' '}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </div>
);

export default CustomConfirmationModal;
