/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, ChangeEvent, FocusEvent } from 'react';
import Flatpickr from "react-flatpickr";
import get from 'lodash/get';

import useFormContext from '@hooks/useFormContext';

import { BaseFormComponentProps } from '@models/forms';

import { renderFormError, renderFormLabel } from '@utils/forms';

export interface BaseDateProps {
    disabled?: boolean;
    error?: boolean;
    invalid?: boolean;
    focused?: boolean;
    name?: string;
    onBlur?(e: FocusEvent<HTMLTextAreaElement>): void;
    onChange?(e: ChangeEvent<HTMLTextAreaElement>): void;
    placeholder?: string;
    value: string;
}

type ComponentProps = BaseFormComponentProps<BaseDateProps>;

const FormDate: FC<ComponentProps> = ({
    hideErrorBlock = false,
    hideErrorText = false,
    label,
    ...baseDateProps
}) => {
    const { name } = baseDateProps;
    const { errors, values, touched, setFieldValue } = useFormContext();

    const error = !!get(errors, name) && !!get(touched, name);
    const value = get(values, name);

    return (
        <div className="form-row">
            <div className="form-group col-md-12">
                {renderFormLabel(name, label)}
                <br />
                <Flatpickr
                    {...baseDateProps}
                    onChange={(dates: Date[], currentDateString: string) => { setFieldValue(name, dates[0]) }}
                    options={{
                        enableTime: true,
                    }}
                    value={value}
                />
                {hideErrorBlock ? null : renderFormError(name, hideErrorText)}
            </div>
        </div>
    );
};

export default FormDate;
