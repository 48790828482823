import React from 'react';

import Image from '@components/common/Image/Image';
import { EditButton, IconButton, DeleteButton } from '@components/buttons';

import { IProduct } from '@models/product';

interface Props {
  product: IProduct;
  onEditClick: ((product: IProduct) => void) | null;
  onDeleteClick: ((product: IProduct) => void) | null;
  onSelectChannelClick: ((product: IProduct, channelId: number) => void) | null;
  // channelsList: IChannel[];
}

const ProductListItem: React.FC<Props> = ({
  product,
  onEditClick,
  onDeleteClick,
  onSelectChannelClick,
  // channelsList,
}) => {
  return (
    <div className="search_result col-12 col-sm-12 col-md-6" key={product.id}>
      <div className="float-left col-md-4 col-sm-3 col-5">
        <Image
          alt="search-result"
          className="img-fluid"
          width={100}
          height={100}
          src={product.thumbnail}
          fallbackSrc={'https://via.placeholder.com/100'}
        />
      </div>
      <div className="float-left col-md-8 col-sm-9 col-7">
        <h4 className="searchtitle">
          {product.title}
          {product.url && (
            <a href={product.url} target="_blank" rel="noreferrer">
              <IconButton icon="fa-external-link" />
            </a>
          )}
        </h4>
        {/* <p className="searchauthor">
        Vendor: <a href="#!">{product.vendor_name}</a>
      </p> */}
        <div style={{ marginTop: '10px', marginLeft: '-12px', display: 'flex' }}>
          {onEditClick && <EditButton onClick={() => onEditClick(product)} />}{' '}
          {/* {onSelectChannelClick && (
          <SelectChannelButton
            handleClick={(channelId: number) => onSelectChannelClick(product, channelId)}
            channelsList={channelsList}
          />
        )}{' '} */}
          {onDeleteClick && <DeleteButton onClick={() => onDeleteClick(product)} />}
        </div>
      </div>
    </div>
  )
};

export default ProductListItem;
